import React, { useState, useEffect } from 'react';
import './styles/kontakt.css';
import { FaEnvelope, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import profileImage from '../images/Louise Munk-35.jpg';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Kontakt = () => {
  const [profileData, setProfileData] = useState({
    milestones: [],
    specialties: { areas: [] }
  });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'forside');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.profile) {
            setProfileData({
              milestones: data.profile.milestones || [],
              specialties: data.profile.specialties || { areas: [] }
            });
          }
        }
      } catch (error) {
        console.error('Fejl ved hentning af profil data:', error);
      }
    };

    fetchContent();
  }, []);

  return (
    <section className="kontakt" id="kontakt">
      <div className="kontakt-container">
        <div className="kontakt-header">
          <span className="kontakt-subtitle">Kontakt</span>
          <h2>Tag det første skridt</h2>
          <p>
            Send mig en mail med en kort beskrivelse af din situation, 
            så vender jeg tilbage til dig hurtigst muligt.
          </p>
        </div>

        <div className="kontakt-content">
          <div className="kontakt-info">
            <div className="info-card">
              <div className="info-icon">
                <FaEnvelope />
              </div>
              <h3>Send en mail</h3>
              <p><a href="mailto:louisemunk@protonmail.com">louisemunk@protonmail.com</a></p>
              <span className="info-note">Jeg svarer inden for få hverdage</span>
            </div>

            <div className="info-card">
              <div className="info-icon">
                <FaMapMarkerAlt />
              </div>
              <h3>Klinikken</h3>
              <p>Dytmærsken 8, 1.</p>
              <span className="info-note">8900 Randers C</span>
            </div>

            <div className="info-card">
              <div className="info-icon">
                <FaClock />
              </div>
              <h3>Åbningstider</h3>
              <p>Man-Fre: 9:00-16:00</p>
              <span className="info-note">Weekend: Efter aftale</span>
            </div>
          </div>

          <div className="praktisk-info">
            <div className="info-box">
              <h3>Betaling</h3>
              <p>
                Betaling foregår i starten af hver samtale. Der kan betales med konto-overførsel. 
                Inden første samtale skal du installere din banks mobilapp (hvis du ikke allerede har den). 
                Jeg guider dig i første samtale ift. hvordan du sætter klinikken op som fast modtager.
              </p>
            </div>
            <div className="info-box">
              <h3>Afbud/udeblivelse</h3>
              <p>
                Hvis du er forhindret i at komme til den aftalte tid, skal du melde afbud pr. sms 
                senest dagen inden, før kl. 12. Ellers betales fuld takst, uanset årsag. 
                Afbud pr. mail eller telefonsvarer, gælder ikke som rettidigt afbud.
              </p>
            </div>
          </div>
        </div>

        <div className="profile-section">
          <h2 className="profile-mobile-title">Profil</h2>
          
          <div className="profile-image-container">
            <img src={profileImage} alt="Louise Munk" className="profile-image" />
            <div className="profile-credentials">
              {profileData.milestones.map((milestone, index) => (
                <div key={index} className="credential-item">
                  <span className="year">{milestone.year}</span>
                  <span className="detail">{milestone.detail}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="profile-content">
            <h2>Profil</h2>
            <div className="profile-text">
              <h3>Min tilgang</h3>
              <p>
                Jeg har særlig interesse for mennesker, som i deres opvækst ikke har lært at håndtere livets udfordringer. Min tilgang er baseret på forståelse og accept, hvor vi sammen udforsker dine mønstre og finder nye veje.
              </p>
              <p>
                Mit fokus er at hjælpe dig med at forstå dig selv bedre og udvikle mere hensigtsmæssige måder at håndtere livets udfordringer på. Dette gør vi gennem en kombination af samtale, refleksion og konkrete værktøjer.
              </p>
              <div className="profile-specialties">
                <h4>Specialeområder</h4>
                <ul>
                  {profileData.specialties.areas.map((specialty, index) => (
                    <li key={index}>{specialty}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Kontakt;
