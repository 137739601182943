import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import '../styles/adminBehandling.css';
import { useNavigate } from 'react-router-dom';

const AdminBehandling = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [subtitle, setSubtitle] = useState('Behandling');
  const [title, setTitle] = useState('Hvordan vælger man den "rigtige" psykolog?');
  const [introText, setIntroText] = useState(`Måske sidder du lige nu med spørgsmålet: hvordan skal jeg vælge den "rigtige" psykolog for mig? Tjaa… det er der ikke noget enkelt svar på -og for at være helt ærlig: Det ved man ofte først, når man ER startet i terapi😊

For at hjælpe dig så godt som muligt, vil jeg her beskrive den måde jeg arbejder på. Når du læser det, så prøv at mærke efter (så godt du kan), om du kunne tænke dig at starte i terapi hos mig😊`);
  const [metaphorTitle, setMetaphorTitle] = useState('At finde vej i livet');
  const [metaphorText, setMetaphorText] = useState(`Forestil dig, at du bliver bedt om at finde hen til Nordens smukkeste sø -men at du er alene og ikke får noget kort til vejen derhen. What to do?

Ligesådan er det ofte i livet: vi vil gerne finde derhen, hvor der er ro og hvor det er rart at være. Vejen derhen finder vi ved at kunne mærke og forstå vores behov, følelser og tanker samt have gode, nærende forhold til andre mennesker.`);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [explanationTitle, setExplanationTitle] = useState('Hvordan lærer vi at håndtere livet?');
  const [explanationText, setExplanationText] = useState(`Det kan lyde ligetil, men for mange, er det slet ikke sådan. Det kræver ofte mange års omsorgsfuld og nærværende støtte, at kunne forstå hvad der sker både inde i os selv og i vores relationer. Nogle vokser op med sådan en støtte, men andre gør ikke.

Hvis vi ikke fra starten af livet lærer hensigtsmæssige måder at klare os på i livet, så lærer vi ofte mere uhensigtsmæssige måder. Det kan fx være, at vi bliver "gode" til at gøre os følelsesløse eller at vi bliver "gode" til at handle på alle de impulser, der kommer. Det er ikke fordi vi er dumme. Ofte var det så godt, som vi kunne gøre det på det tidspunkt i vores liv.`);
  const [approachTitle, setApproachTitle] = useState('Min terapeutiske tilgang');
  const [approachText, setApproachText] = useState(`Når vi senere i livet møder udfordringer, kan de være for svære at løse selv. Vi mennesker har behov for hinandens hjælp til at kunne forstå de komplekse processer og sårbare følelser, som foregår i os selv og i vores relationer.

Det kan skabe en del nervøsitet, at skulle møde en behandler for første gang. Her er det vigtigt at du ved, at jeg møder dig med nysgerrig, varme og nærvær. Jeg arbejder især med en terapiform, som kaldes Mentaliseringsbaseret terapi.

Min tilgang til problemer er, at vi må finde ud af hvorfor noget i livet er blevet problematisk for dig. Vores fokus er primært på din måde at håndtere udfordringerne på. Jeg ved, at dette kan være svært at forstå, men prøv at læse videre, så giver det måske mere mening😊`);
  const [expectationsTitle, setExpectationsTitle] = useState('Hvad kan du forvente?');
  const [expectationsText, setExpectationsText] = useState(`Helt grundlæggende arbejder vi med at se på din andel i problemerne. Hvis du er optaget af hvordan andre omkring dig "er problemet", så kan vi ikke løse det. Vi kan kun arbejde med hvad du kan gøre anderledes.

Det betyder også, at du må være aktiv, både i behandlingen og i livet, for at få effekt af terapien. Det er også vigtigt, at du har nogenlunde realistiske forventninger til terapien.

Lidt hårdt sat op: terapi er ikke kirurgi. Jeg kan ikke som en anden kirurg "skære" problemerne væk. I stedet skal problemerne "opløses" eller mindskes, ved at du lærer bedre strategier til at håndtere dem. Jeg kan hjælpe med at vise dig vejen, og du skal selv "gå vejen" -hvis du vil😊`);
  const [processTitle, setProcessTitle] = useState('Behandlingsforløbet');
  const [processSteps, setProcessSteps] = useState([
    {
      title: "Forberedelse",
      text: "Inden du starter, sender jeg dig nogle spørgsmål om din nuværende forståelse af din problematik og hvad du ønsker at opnå med terapien. Dette hjælper dig med at være forberedt til første samtale."
    },
    {
      title: "Første samtale",
      text: "I første samtale taler vi om din situation og dine udfordringer. Vi undersøger sammen, hvordan jeg bedst kan hjælpe dig, og hvilken type behandling der passer bedst til dine behov."
    },
    {
      title: "Behandlingsplan",
      text: "I anden samtale samler vi alle oplysningerne og laver en konkret behandlingsplan. Jo mere præcist vi kan formulere dine mål, jo bedre udgangspunkt for at du får gavn af terapien."
    },
    {
      title: "Det løbende arbejde",
      text: "I de efterfølgende samtaler tager vi udgangspunkt i konkrete situationer, hvor du oplever udfordringer. Din opgave er at lægge mærke til hvornår din problematik særligt viser sig mellem sessionerne."
    }
  ]);
  const [infoCards, setInfoCards] = useState([
    {
      icon: "FaUserFriends",
      title: "Din aktive deltagelse",
      text: "Du må være aktiv, både i behandlingen og i livet, for at få effekt af terapien. Vi kan kun arbejde med hvad du kan gøre anderledes. Terapi er ikke kirurgi - jeg kan ikke \"skære\" problemerne væk, men jeg kan hjælpe dig med at finde nye veje frem."
    },
    {
      icon: "FaRegComments",
      title: "Tryghed i terapien",
      text: "Det kan skabe nervøsitet at skulle møde en behandler for første gang. Her er det vigtigt at du ved, at jeg møder dig med nysgerrighed, varme og nærvær. Det handler om at skabe et trygt rum, hvor du kan udforske dine tanker og følelser."
    }
  ]);
  const [contactTitle, setContactTitle] = useState("Interesseret i at starte et forløb?");
  const [contactText, setContactText] = useState(`Jeg håber, at du har fået en idé om hvad du kan forvente, hvis du starter i forløb hos mig😊

Hvis du ønsker et forløb, så send mig en mail på: louisemunk@protonmail.com
Skriv kort hvad det drejer sig om, din alder og dit telefonnummer, så hører du fra mig hurtigst muligt.`);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'behandling');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.subtitle) setSubtitle(data.subtitle);
          if (data.title) setTitle(data.title);
          if (data.introText) setIntroText(data.introText);
          if (data.metaphorTitle) setMetaphorTitle(data.metaphorTitle);
          if (data.metaphorText) setMetaphorText(data.metaphorText);
          if (data.explanationTitle) setExplanationTitle(data.explanationTitle);
          if (data.explanationText) setExplanationText(data.explanationText);
          if (data.approachTitle) setApproachTitle(data.approachTitle);
          if (data.approachText) setApproachText(data.approachText);
          if (data.expectationsTitle) setExpectationsTitle(data.expectationsTitle);
          if (data.expectationsText) setExpectationsText(data.expectationsText);
          if (data.processTitle) setProcessTitle(data.processTitle);
          if (data.processSteps) setProcessSteps(data.processSteps);
          if (data.infoCards) setInfoCards(data.infoCards);
          if (data.contactTitle) setContactTitle(data.contactTitle);
          if (data.contactText) setContactText(data.contactText);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Fejl ved hentning af indhold:', error);
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await setDoc(doc(db, 'content', 'behandling'), {
        subtitle,
        title,
        introText,
        metaphorTitle,
        metaphorText,
        explanationTitle,
        explanationText,
        approachTitle,
        approachText,
        expectationsTitle,
        expectationsText,
        processTitle,
        processSteps,
        infoCards,
        contactTitle,
        contactText
      }, { merge: true });
      
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      console.error('Fejl ved gemning:', error);
      alert('Der skete en fejl ved gemning af ændringer');
    }
    setIsSaving(false);
  };

  const handleStepChange = (index, field, value) => {
    const newSteps = [...processSteps];
    newSteps[index] = { ...newSteps[index], [field]: value };
    setProcessSteps(newSteps);
  };

  const handleInfoCardChange = (index, field, value) => {
    const newCards = [...infoCards];
    newCards[index] = { ...newCards[index], [field]: value };
    setInfoCards(newCards);
  };

  if (isLoading) {
    return <div className="admin-loading">Indlæser...</div>;
  }

  return (
    <div className="admin-behandling">
      <div className="admin-header">
        <div className="admin-header-left">
          <button 
            onClick={() => navigate('/admin/dashboard')} 
            className="back-button"
          >
            ← Tilbage
          </button>
          <h1>Rediger Behandlingsside</h1>
        </div>
        <button 
          onClick={handleSave} 
          className="save-button"
          disabled={isSaving}
        >
          {isSaving ? 'Gemmer...' : 'Gem ændringer'}
        </button>
      </div>

      {showSuccessMessage && (
        <div className="success-message">
          Ændringerne blev gemt!
        </div>
      )}

      <div className="edit-section">
        <h2>Hero Sektion</h2>
        <div className="input-group">
          <label>Undertitel</label>
          <input
            type="text"
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Hovedtitel</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="text-input"
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Introduktion</h2>
        <div className="input-group">
          <label>Introduktionstekst</label>
          <textarea
            value={introText}
            onChange={(e) => setIntroText(e.target.value)}
            className="text-input textarea"
            rows={6}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Metafor Sektion</h2>
        <div className="input-group">
          <label>Metafor Titel</label>
          <input
            type="text"
            value={metaphorTitle}
            onChange={(e) => setMetaphorTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Metafor Tekst</label>
          <textarea
            value={metaphorText}
            onChange={(e) => setMetaphorText(e.target.value)}
            className="text-input textarea"
            rows={6}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Forklaring</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={explanationTitle}
            onChange={(e) => setExplanationTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={explanationText}
            onChange={(e) => setExplanationText(e.target.value)}
            className="text-input textarea"
            rows={8}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Terapeutisk Tilgang</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={approachTitle}
            onChange={(e) => setApproachTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={approachText}
            onChange={(e) => setApproachText(e.target.value)}
            className="text-input textarea"
            rows={8}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Forventninger</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={expectationsTitle}
            onChange={(e) => setExpectationsTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={expectationsText}
            onChange={(e) => setExpectationsText(e.target.value)}
            className="text-input textarea"
            rows={8}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Behandlingsforløb</h2>
        <div className="input-group">
          <label>Sektionens titel</label>
          <input
            type="text"
            value={processTitle}
            onChange={(e) => setProcessTitle(e.target.value)}
            className="text-input"
          />
        </div>
        
        <div className="process-steps-editor">
          {processSteps.map((step, index) => (
            <div key={index} className="process-step-edit">
              <div className="step-header">
                <h3>Trin {index + 1}</h3>
              </div>
              <div className="input-group">
                <label>Titel</label>
                <input
                  type="text"
                  value={step.title}
                  onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Beskrivelse</label>
                <textarea
                  value={step.text}
                  onChange={(e) => handleStepChange(index, 'text', e.target.value)}
                  className="text-input textarea"
                  rows={4}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Info Kort</h2>
        <div className="info-cards-editor">
          {infoCards.map((card, index) => (
            <div key={index} className="info-card-edit">
              <div className="card-header">
                <h3>Kort {index + 1}</h3>
              </div>
              <div className="input-group">
                <label>Titel</label>
                <input
                  type="text"
                  value={card.title}
                  onChange={(e) => handleInfoCardChange(index, 'title', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Tekst</label>
                <textarea
                  value={card.text}
                  onChange={(e) => handleInfoCardChange(index, 'text', e.target.value)}
                  className="text-input textarea"
                  rows={4}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Kontakt Sektion</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={contactTitle}
            onChange={(e) => setContactTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={contactText}
            onChange={(e) => setContactText(e.target.value)}
            className="text-input textarea"
            rows={6}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminBehandling; 