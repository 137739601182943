import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import '../styles/AdminForside.css';
import { useNavigate } from 'react-router-dom';
import { 
  FaHeart, FaBrain, FaUsers, FaStar, 
  FaHandHoldingHeart, FaUserMd, FaComments, 
  FaLightbulb, FaBalanceScale, FaShieldAlt, FaHeartbeat, FaCloud 
} from 'react-icons/fa';
import { collection, getDocs } from 'firebase/firestore';

const AdminForside = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  
  // Hero section states
  const [heroSubtitle, setHeroSubtitle] = useState('Psykolog i Randers');
  const [heroTitle, setHeroTitle] = useState('Louise Munk');
  const [heroText, setHeroText] = useState('Specialiseret i at hjælpe mennesker med at finde deres indre styrke og skabe positive forandringer i deres liv.');
  const [heroButtons, setHeroButtons] = useState([
    {
      text: 'Læs mere',
      link: 'behandling',
      type: 'primary'
    },
    {
      text: 'Kontakt',
      link: '#kontakt',
      type: 'secondary'
    }
  ]);

  // Tilføj nye states for profil sektion
  const [profileName, setProfileName] = useState('Psykolog Louise Munk');
  const [profileAddress, setProfileAddress] = useState({
    street: 'Dytmærsken 8, 1.',
    city: '8900 Randers C'
  });
  const [profileContact, setProfileContact] = useState({
    phone: '30 48 13 03',
    email: 'louisemunk@protonmail.com'
  });
  const [profileInfo, setProfileInfo] = useState({
    cvr: '40220747',
    ydernummer: '771449'
  });

  // Tilføj states for Om-sektion
  const [aboutSubtitle, setAboutSubtitle] = useState('Om mig');
  const [aboutTitle, setAboutTitle] = useState('Erfaren psykolog med fokus på dig');
  const [aboutText, setAboutText] = useState('Med over 10 års erfaring som psykolog har jeg specialiseret mig i at hjælpe mennesker med at finde deres indre styrke og skabe positive forandringer i deres liv.');
  const [aboutFeatures, setAboutFeatures] = useState([
    {
      icon: "FaHeart",
      title: "Empatisk tilgang",
      text: "Skaber et trygt og forstående rum, hvor du kan være dig selv og udforske dine tanker og følelser i dit eget tempo."
    },
    {
      icon: "FaBrain",
      title: "Evidensbaseret",
      text: "Arbejder med dokumenterede metoder og teknikker, der er udviklet på baggrund af den nyeste forskning inden for psykologi."
    },
    {
      icon: "FaUsers",
      title: "Individuel tilpasning",
      text: "Tilrettelægger forløbet efter dine specifikke behov og mål, så du får den bedst mulige støtte og udvikling."
    },
    {
      icon: "FaStar",
      title: "Bred erfaring",
      text: "Har gennem årene hjulpet mennesker med forskellige udfordringer og livssituationer til at opnå positive forandringer."
    }
  ]);

  // Tilføj states for Behandling-sektion
  const [treatmentSubtitle, setTreatmentSubtitle] = useState('Behandling');
  const [treatmentTitle, setTreatmentTitle] = useState('Professionel psykologhjælp');
  const [treatmentText, setTreatmentText] = useState('Som erfaren psykolog tilbyder jeg forskellige former for terapi og behandling, tilpasset dine individuelle behov og udfordringer.');
  const [treatmentServices, setTreatmentServices] = useState([
    {
      title: "Individuel terapi",
      text: "Et fortroligt rum hvor du kan udforske dine tanker og følelser, og få professionel støtte til at håndtere livets udfordringer."
    },
    {
      title: "Pårørende",
      text: "Som pårørende kan du enten få et selvstændigt forløb om dine udfordringer ved at være pårørende, eller deltage i udvalgte samtaler i din næres forløb efter forudgående aftale. Begge muligheder er velkomne i klinikken."
    },
    {
      title: "Forløb",
      text: "Skræddersyede behandlingsforløb der tager udgangspunkt i dine behov, med fokus på både kortsigtede og langsigtede mål."
    },
    {
      title: "Mentaliseringsbaseret terapi (MBT)",
      text: "MBT er en veldokumenteret og nænsom metode til at udforske hvad der sker i dig, når noget bliver svært. I forløb med MBT er der desuden særligt fokus på at få dine livsvigtige relationer til andre mennesker til at fungere optimalt."
    }
  ]);
  const [treatmentSteps, setTreatmentSteps] = useState([
    {
      number: "01",
      title: "Første kontakt",
      text: "Send en mail med en kort beskrivelse af din problematik samt dit telefonnummer, så ringer jeg til dig for en uforpligtende samtale om dine behov for behandling."
    },
    {
      number: "02",
      title: "Indledende samtale",
      text: "Vi mødes og taler om din situation og hvilken type behandling der passer bedst."
    },
    {
      number: "03",
      title: "Behandlingsplan",
      text: "Sammen lægger vi en plan for dit forløb med konkrete mål og tidsperspektiv."
    },
    {
      number: "04",
      title: "Løbende evaluering",
      text: "Vi følger op på din udvikling og tilpasser behandlingen efter behov."
    }
  ]);
  const [treatmentButtons, setTreatmentButtons] = useState([
    {
      text: "Kontakt mig",
      link: "#kontakt"
    },
    {
      text: "Læs mere",
      link: "behandling"
    }
  ]);

  // Tilføj states for Inspiration-sektion
  const [inspirationSubtitle, setInspirationSubtitle] = useState('Inspiration');
  const [inspirationTitle, setInspirationTitle] = useState('Ressourcer til din udvikling');
  const [inspirationText, setInspirationText] = useState('Her finder du inspiration og viden om forskellige psykologiske emner. Udforsk ressourcerne og find hjælp til netop dine udfordringer.');
  const [inspirationResources, setInspirationResources] = useState([
    {
      title: "Depression",
      text: "Hør mere om depression og få en dybere forståelse for, hvad depression er, og hvordan det kan opleves.",
      buttonText: "Udforsk ressourcer"
    },
    {
      title: "Angst",
      text: "Læs om forskellige former for angst og få indblik i, hvordan angst kan påvirke hverdagen.",
      buttonText: "Udforsk ressourcer"
    },
    {
      title: "Stress",
      text: "Få viden om stress og kroppens reaktioner, samt hvordan stress kan komme til udtryk i hverdagen.",
      buttonText: "Udforsk ressourcer"
    },
    {
      title: "Sorg",
      text: "Læs om sorg og de forskellige måder at opleve og reagere på tab og svære forandringer.",
      buttonText: "Udforsk ressourcer"
    },
    {
      title: "Traumer",
      text: "Få indblik i hvordan traumer kan påvirke os, og hvordan traumatiske oplevelser kan bearbejdes.",
      buttonText: "Udforsk ressourcer"
    },
    {
      title: "Selvværd",
      text: "Hør om selvværd og selvopfattelse, og hvordan vores forhold til os selv påvirker vores liv.",
      buttonText: "Udforsk ressourcer"
    }
  ]);

  // Tilføj states for Kontakt-sektion
  const [contactSubtitle, setContactSubtitle] = useState('Kontakt');
  const [contactTitle, setContactTitle] = useState('Tag det første skridt');
  const [contactText, setContactText] = useState('Send mig en mail med en kort beskrivelse af din situation, så vender jeg tilbage til dig hurtigst muligt.');
  const [contactEmail, setContactEmail] = useState({
    buttonText: 'Send en mail',
    email: 'louisemunk@protonmail.com',
    responseTime: 'Jeg svarer inden for få hverdage'
  });
  const [contactClinic, setContactClinic] = useState({
    title: 'Klinikken',
    address: {
      street: 'Dytmærsken 8, 1.',
      city: '8900 Randers C'
    }
  });
  const [contactHours, setContactHours] = useState({
    title: 'Åbningstider',
    weekdays: 'Man-Fre: 9:00-16:00',
    weekend: 'Weekend: Efter aftale'
  });
  const [contactPolicies, setContactPolicies] = useState([
    {
      title: 'Betaling',
      text: 'Betaling foregår i starten af hver samtale. Der kan betales med konto-overførsel. Inden første samtale skal du installere din banks mobilapp (hvis du ikke allerede har den). Jeg guider dig i første samtale ift. hvordan du sætter klinikken op som fast modtager.'
    },
    {
      title: 'Afbud/udeblivelse',
      text: 'Hvis du er forhindret i at komme til den aftalte tid, skal du melde afbud pr. sms senest dagen inden, før kl. 12. Ellers betales fuld takst, uanset årsag. Afbud pr. mail eller telefonsvarer, gælder ikke som rettidigt afbud.'
    }
  ]);

  // Tilføj states for Profil-sektion
  const [profileMilestones, setProfileMilestones] = useState([
    {
      year: "2010",
      text: "Uddannet psykolog fra Aarhus Universitet"
    },
    {
      year: "2012",
      text: "Autoriseret psykolog"
    },
    {
      year: "2018",
      text: "Egen praksis i Randers"
    },
    {
      year: "2022",
      text: "Specialisering i Mentaliseringsbaseret terapi"
    }
  ]);
  const [profileApproach, setProfileApproach] = useState({
    title: "Min tilgang",
    paragraphs: [
      "Jeg har særlig interesse for mennesker, som i deres opvækst ikke har lært at håndtere livets udfordringer. Min tilgang er baseret på forståelse og accept, hvor vi sammen udforsker dine mønstre og finder nye veje.",
      "Mit fokus er at hjælpe dig med at forstå dig selv bedre og udvikle mere hensigtsmæssige måder at håndtere livets udfordringer på. Dette gør vi gennem en kombination af samtale, refleksion og konkrete værktøjer."
    ]
  });
  const [profileSpecialties, setProfileSpecialties] = useState({
    title: "Specialeområder",
    areas: [
      "Depression",
      "Angst",
      "Relationelle udfordringer",
      "Traumer",
      "Selvværd",
      "Problematisk spisemønster",
      "Sorg",
      "Stress"
    ]
  });

  // Tilføj states for FAQ-sektion
  const [faqSubtitle, setFaqSubtitle] = useState('FAQ');
  const [faqTitle, setFaqTitle] = useState('Ofte stillede spørgsmål');
  const [faqText, setFaqText] = useState('Her finder du svar på nogle af de mest almindelige spørgsmål om psykologhjælp.');
  const [faqItems, setFaqItems] = useState([
    {
      question: "Hvad koster det?",
      answer: "Prisen for en konsultation er 1.000 kr. Med henvisning fra egen læge er egenbetalingen 350 kr. pr. konsultation."
    },
    {
      question: "Hvor lang tid tager det?",
      answer: "En konsultation varer 50 minutter. Antallet af samtaler afhænger af dine behov og udfordringer."
    },
    {
      question: "Hvordan foregår første samtale?",
      answer: "I den første samtale taler vi om, hvad der har fået dig til at søge hjælp. Vi afdækker dine udfordringer og behov, og lægger en plan for det videre forløb."
    },
    {
      question: "Har du tavshedspligt?",
      answer: "Ja, som autoriseret psykolog har jeg tavshedspligt. Det betyder, at alt hvad du fortæller mig er fortroligt."
    }
  ]);

  const [inspirationCategories, setInspirationCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newInspirationTitle, setNewInspirationTitle] = useState('');
  const [newInspirationIcon, setNewInspirationIcon] = useState('');
  const [inspirationItems, setInspirationItems] = useState([]);

  const linkOptions = [
    { value: 'behandling', label: 'Behandling' },
    { value: 'priser', label: 'Priser' },
    { value: 'inspiration', label: 'Inspiration' },
    { value: '#kontakt', label: 'Kontakt (Forside)' },
    { value: '#om', label: 'Om (Forside)' },
    { value: '#faq', label: 'FAQ (Forside)' },
    { value: '#profil', label: 'Profil (Forside)' }
  ];

  const iconOptions = [
    { value: 'FaHeart', label: 'Hjerte', icon: FaHeart },
    { value: 'FaBrain', label: 'Hjerne', icon: FaBrain },
    { value: 'FaUsers', label: 'Gruppe', icon: FaUsers },
    { value: 'FaStar', label: 'Stjerne', icon: FaStar },
    { value: 'FaHandHoldingHeart', label: 'Hjælpende hånd', icon: FaHandHoldingHeart },
    { value: 'FaUserMd', label: 'Behandler', icon: FaUserMd },
    { value: 'FaComments', label: 'Samtale', icon: FaComments },
    { value: 'FaLightbulb', label: 'Idé', icon: FaLightbulb },
    { value: 'FaBalanceScale', label: 'Balance', icon: FaBalanceScale },
    { value: 'FaShieldAlt', label: 'Beskyttelse', icon: FaShieldAlt }
  ];

  const getIcon = (iconName) => {
    switch(iconName) {
      case 'FaHeartbeat':
        return <FaHeartbeat />;
      case 'FaBrain':
        return <FaBrain />;
      case 'FaCloud':
        return <FaCloud />;
      case 'FaHeart':
        return <FaHeart />;
      case 'FaShieldAlt':
        return <FaShieldAlt />;
      case 'FaStar':
        return <FaStar />;
      default:
        return null;
    }
  };

  const handleRemoveInspirationItem = (itemId) => {
    setInspirationItems(inspirationItems.filter(item => item.id !== itemId));
  };

  const handleInspirationItemDescriptionChange = (itemId, newDescription) => {
    setInspirationItems(items => 
      items.map(item => 
        item.id === itemId 
          ? { ...item, description: newDescription }
          : item
      )
    );
  };

  const handleAddMilestone = () => {
    setProfileMilestones([
      ...profileMilestones,
      { year: '', detail: '' }
    ]);
  };

  const handleRemoveMilestone = (index) => {
    setProfileMilestones(
      profileMilestones.filter((_, i) => i !== index)
    );
  };

  const handleAddSpecialty = () => {
    setProfileSpecialties(prev => ({
      ...prev,
      areas: [...prev.areas, '']
    }));
  };

  const handleRemoveSpecialty = (index) => {
    setProfileSpecialties(prev => ({
      ...prev,
      areas: prev.areas.filter((_, i) => i !== index)
    }));
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'forside');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.hero) {
            if (data.hero.subtitle) setHeroSubtitle(data.hero.subtitle);
            if (data.hero.title) setHeroTitle(data.hero.title);
            if (data.hero.text) setHeroText(data.hero.text);
            if (data.hero.buttons) setHeroButtons(data.hero.buttons);
          }
          if (data.profile) {
            if (data.profile.name) setProfileName(data.profile.name);
            if (data.profile.address) setProfileAddress(data.profile.address);
            if (data.profile.contact) setProfileContact(data.profile.contact);
            if (data.profile.info) setProfileInfo(data.profile.info);
            if (data.profile.milestones) setProfileMilestones(data.profile.milestones);
            if (data.profile.approach) setProfileApproach(data.profile.approach);
            if (data.profile.specialties) setProfileSpecialties(data.profile.specialties);
          }
          if (data.about) {
            if (data.about.subtitle) setAboutSubtitle(data.about.subtitle);
            if (data.about.title) setAboutTitle(data.about.title);
            if (data.about.text) setAboutText(data.about.text);
            if (data.about.features) setAboutFeatures(data.about.features);
          }
          if (data.treatment) {
            if (data.treatment.subtitle) setTreatmentSubtitle(data.treatment.subtitle);
            if (data.treatment.title) setTreatmentTitle(data.treatment.title);
            if (data.treatment.text) setTreatmentText(data.treatment.text);
            if (data.treatment.services) setTreatmentServices(data.treatment.services);
            if (data.treatment.steps) setTreatmentSteps(data.treatment.steps);
            if (data.treatment.buttons) setTreatmentButtons(data.treatment.buttons);
          }
          if (data.inspiration) {
            if (data.inspiration.subtitle) setInspirationSubtitle(data.inspiration.subtitle);
            if (data.inspiration.title) setInspirationTitle(data.inspiration.title);
            if (data.inspiration.text) setInspirationText(data.inspiration.text);
            if (data.inspiration.resources) setInspirationResources(data.inspiration.resources);
            if (data.inspiration.items) setInspirationItems(data.inspiration.items);
          }
          if (data.contact) {
            if (data.contact.subtitle) setContactSubtitle(data.contact.subtitle);
            if (data.contact.title) setContactTitle(data.contact.title);
            if (data.contact.text) setContactText(data.contact.text);
            if (data.contact.email) setContactEmail(data.contact.email);
            if (data.contact.clinic) setContactClinic(data.contact.clinic);
            if (data.contact.hours) setContactHours(data.contact.hours);
            if (data.contact.policies) setContactPolicies(data.contact.policies);
          }
          if (data.faq) {
            if (data.faq.subtitle) setFaqSubtitle(data.faq.subtitle);
            if (data.faq.title) setFaqTitle(data.faq.title);
            if (data.faq.text) setFaqText(data.faq.text);
            if (data.faq.items) setFaqItems(data.faq.items);
          }
        }

        // Hent inspiration kategorier
        const inspirationDoc = await getDoc(doc(db, 'content', 'inspiration'));
        if (inspirationDoc.exists() && inspirationDoc.data().categories) {
          setInspirationCategories(inspirationDoc.data().categories);
        }
      } catch (error) {
        console.error('Fejl ved hentning af indhold:', error);
      }
      setIsLoading(false);
    };

    fetchContent();
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await setDoc(doc(db, 'content', 'forside'), {
        hero: {
          subtitle: heroSubtitle,
          title: heroTitle,
          text: heroText,
          buttons: heroButtons
        },
        profile: {
          name: profileName,
          address: profileAddress,
          contact: profileContact,
          info: profileInfo,
          milestones: profileMilestones,
          approach: profileApproach,
          specialties: profileSpecialties
        },
        about: {
          subtitle: aboutSubtitle,
          title: aboutTitle,
          text: aboutText,
          features: aboutFeatures
        },
        treatment: {
          subtitle: treatmentSubtitle,
          title: treatmentTitle,
          text: treatmentText,
          services: treatmentServices,
          steps: treatmentSteps,
          buttons: treatmentButtons
        },
        inspiration: {
          subtitle: inspirationSubtitle,
          title: inspirationTitle,
          text: inspirationText,
          resources: inspirationResources,
          items: inspirationItems
        },
        contact: {
          subtitle: contactSubtitle,
          title: contactTitle,
          text: contactText,
          email: contactEmail,
          clinic: contactClinic,
          hours: contactHours,
          policies: contactPolicies
        },
        faq: {
          subtitle: faqSubtitle,
          title: faqTitle,
          text: faqText,
          items: faqItems
        }
      }, { merge: true });
      
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      console.error('Fejl ved gemning:', error);
      alert('Der skete en fejl ved gemning af ændringer');
    }
    setIsSaving(false);
  };

  const handleButtonChange = (index, field, value) => {
    const newButtons = [...heroButtons];
    newButtons[index] = { ...newButtons[index], [field]: value };
    setHeroButtons(newButtons);
  };

  const handleFeatureChange = (index, field, value) => {
    const newFeatures = [...aboutFeatures];
    newFeatures[index] = { ...newFeatures[index], [field]: value };
    setAboutFeatures(newFeatures);
  };

  const handleServiceChange = (index, field, value) => {
    const newServices = [...treatmentServices];
    newServices[index] = { ...newServices[index], [field]: value };
    setTreatmentServices(newServices);
  };

  const handleStepChange = (index, field, value) => {
    const newSteps = [...treatmentSteps];
    newSteps[index] = { ...newSteps[index], [field]: value };
    setTreatmentSteps(newSteps);
  };

  const handleTreatmentButtonChange = (index, field, value) => {
    const newButtons = [...treatmentButtons];
    newButtons[index] = { ...newButtons[index], [field]: value };
    setTreatmentButtons(newButtons);
  };

  const handleResourceChange = (index, field, value) => {
    const newResources = [...inspirationResources];
    newResources[index] = { ...newResources[index], [field]: value };
    setInspirationResources(newResources);
  };

  const handlePolicyChange = (index, field, value) => {
    const newPolicies = [...contactPolicies];
    newPolicies[index] = { ...newPolicies[index], [field]: value };
    setContactPolicies(newPolicies);
  };

  const handleMilestoneChange = (index, field, value) => {
    const newMilestones = [...profileMilestones];
    newMilestones[index] = { ...newMilestones[index], [field]: value };
    setProfileMilestones(newMilestones);
  };

  const handleParagraphChange = (index, value) => {
    const newParagraphs = [...profileApproach.paragraphs];
    newParagraphs[index] = value;
    setProfileApproach({...profileApproach, paragraphs: newParagraphs});
  };

  const handleSpecialtyChange = (index, value) => {
    const newAreas = [...profileSpecialties.areas];
    newAreas[index] = value;
    setProfileSpecialties({...profileSpecialties, areas: newAreas});
  };

  const handleFaqItemChange = (index, field, value) => {
    const newItems = [...faqItems];
    newItems[index] = { ...newItems[index], [field]: value };
    setFaqItems(newItems);
  };

  const handleAddInspirationItem = () => {
    if (newInspirationTitle.trim() && selectedCategory) {
      const newItem = {
        id: Date.now(),
        title: newInspirationTitle.trim(),
        icon: newInspirationIcon,
        category: selectedCategory,
        description: ''
      };
      
      setInspirationItems([...inspirationItems, newItem]);
      setNewInspirationTitle('');
      setNewInspirationIcon('');
      setSelectedCategory('');
    }
  };

  if (isLoading) {
    return <div className="admin-loading">Indlæser...</div>;
  }

  return (
    <div className="admin-forside">
      <div className="admin-header">
        <div className="admin-header-left">
          <button 
            onClick={() => navigate('/admin/dashboard')} 
            className="back-button"
          >
            ← Tilbage
          </button>
          <h1>Rediger Forside</h1>
        </div>
        <button 
          onClick={handleSave} 
          className="save-button"
          disabled={isSaving}
        >
          {isSaving ? 'Gemmer...' : 'Gem ændringer'}
        </button>
      </div>

      {showSuccessMessage && (
        <div className="success-message">
          Ændringerne blev gemt!
        </div>
      )}

      <div className="edit-section">
        <h2>Hero Sektion</h2>
        <div className="input-group">
          <label>Undertitel</label>
          <input
            type="text"
            value={heroSubtitle}
            onChange={(e) => setHeroSubtitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={heroTitle}
            onChange={(e) => setHeroTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={heroText}
            onChange={(e) => setHeroText(e.target.value)}
            className="text-input textarea"
            rows={4}
          />
        </div>

        <div className="buttons-editor">
          <h3>Knapper</h3>
          {heroButtons.map((button, index) => (
            <div key={index} className="button-edit">
              <div className="input-group">
                <label>Tekst</label>
                <input
                  type="text"
                  value={button.text}
                  onChange={(e) => handleButtonChange(index, 'text', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Link til</label>
                <select
                  value={button.link}
                  onChange={(e) => handleButtonChange(index, 'link', e.target.value)}
                  className="select-input"
                >
                  {linkOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Profil Information</h2>
        <div className="input-group">
          <label>Navn</label>
          <input
            type="text"
            value={profileName}
            onChange={(e) => setProfileName(e.target.value)}
            className="text-input"
          />
        </div>

        <div className="address-group">
          <h3>Adresse</h3>
          <div className="input-group">
            <label>Gade</label>
            <input
              type="text"
              value={profileAddress.street}
              onChange={(e) => setProfileAddress({...profileAddress, street: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>By</label>
            <input
              type="text"
              value={profileAddress.city}
              onChange={(e) => setProfileAddress({...profileAddress, city: e.target.value})}
              className="text-input"
            />
          </div>
        </div>

        <div className="contact-group">
          <h3>Kontakt</h3>
          <div className="input-group">
            <label>Telefon</label>
            <input
              type="text"
              value={profileContact.phone}
              onChange={(e) => setProfileContact({...profileContact, phone: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              type="text"
              value={profileContact.email}
              onChange={(e) => setProfileContact({...profileContact, email: e.target.value})}
              className="text-input"
            />
          </div>
        </div>

        <div className="info-group">
          <h3>Virksomhedsinfo</h3>
          <div className="input-group">
            <label>CVR-nummer</label>
            <input
              type="text"
              value={profileInfo.cvr}
              onChange={(e) => setProfileInfo({...profileInfo, cvr: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Ydernummer</label>
            <input
              type="text"
              value={profileInfo.ydernummer}
              onChange={(e) => setProfileInfo({...profileInfo, ydernummer: e.target.value})}
              className="text-input"
            />
          </div>
        </div>
      </div>

      <div className="edit-section">
        <h2>Om Mig Sektion</h2>
        <div className="input-group">
          <label>Undertitel</label>
          <input
            type="text"
            value={aboutSubtitle}
            onChange={(e) => setAboutSubtitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={aboutTitle}
            onChange={(e) => setAboutTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={aboutText}
            onChange={(e) => setAboutText(e.target.value)}
            className="text-input textarea"
            rows={4}
          />
        </div>

        <div className="features-editor">
          <h3>Fokusområder</h3>
          {aboutFeatures.map((feature, index) => (
            <div key={index} className="feature-edit">
              <div className="feature-header">
                <h4>Fokusområde {index + 1}</h4>
                <div className="feature-icon">
                  {iconOptions.find(opt => opt.value === feature.icon)?.icon && 
                    React.createElement(iconOptions.find(opt => opt.value === feature.icon).icon)}
                </div>
              </div>
              <div className="input-group">
                <label>Ikon</label>
                <select
                  value={feature.icon}
                  onChange={(e) => handleFeatureChange(index, 'icon', e.target.value)}
                  className="select-input"
                >
                  {iconOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-group">
                <label>Titel</label>
                <input
                  type="text"
                  value={feature.title}
                  onChange={(e) => handleFeatureChange(index, 'title', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Beskrivelse</label>
                <textarea
                  value={feature.text}
                  onChange={(e) => handleFeatureChange(index, 'text', e.target.value)}
                  className="text-input textarea"
                  rows={3}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Behandling Sektion</h2>
        <div className="input-group">
          <label>Undertitel</label>
          <input
            type="text"
            value={treatmentSubtitle}
            onChange={(e) => setTreatmentSubtitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={treatmentTitle}
            onChange={(e) => setTreatmentTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={treatmentText}
            onChange={(e) => setTreatmentText(e.target.value)}
            className="text-input textarea"
            rows={4}
          />
        </div>

        <div className="services-editor">
          <h3>Behandlingstilbud</h3>
          {treatmentServices.map((service, index) => (
            <div key={index} className="service-edit">
              <div className="service-header">
                <h4>Tilbud {index + 1}</h4>
              </div>
              <div className="input-group">
                <label>Titel</label>
                <input
                  type="text"
                  value={service.title}
                  onChange={(e) => handleServiceChange(index, 'title', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Beskrivelse</label>
                <textarea
                  value={service.text}
                  onChange={(e) => handleServiceChange(index, 'text', e.target.value)}
                  className="text-input textarea"
                  rows={3}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="steps-editor">
          <h3>Sådan foregår det</h3>
          {treatmentSteps.map((step, index) => (
            <div key={index} className="step-edit">
              <div className="step-header">
                <h4>Trin {step.number}</h4>
              </div>
              <div className="input-group">
                <label>Titel</label>
                <input
                  type="text"
                  value={step.title}
                  onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Beskrivelse</label>
                <textarea
                  value={step.text}
                  onChange={(e) => handleStepChange(index, 'text', e.target.value)}
                  className="text-input textarea"
                  rows={3}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="treatment-buttons-editor">
          <h3>Knapper</h3>
          {treatmentButtons.map((button, index) => (
            <div key={index} className="button-edit">
              <div className="input-group">
                <label>Tekst</label>
                <input
                  type="text"
                  value={button.text}
                  onChange={(e) => handleTreatmentButtonChange(index, 'text', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Link til</label>
                <select
                  value={button.link}
                  onChange={(e) => handleTreatmentButtonChange(index, 'link', e.target.value)}
                  className="select-input"
                >
                  {linkOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Inspiration Sektion</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={newInspirationTitle}
            onChange={(e) => setNewInspirationTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Vælg emne</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="select-input"
          >
            <option value="">Vælg et emne</option>
            {inspirationCategories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label>Ikon</label>
          <select
            value={newInspirationIcon}
            onChange={(e) => setNewInspirationIcon(e.target.value)}
            className="select-input"
          >
            <option value="">Vælg et ikon</option>
            <option value="FaHeartbeat">Hjerte</option>
            <option value="FaBrain">Hjerne</option>
            <option value="FaCloud">Sky</option>
            <option value="FaHeart">Hjerte (fyldt)</option>
            <option value="FaShieldAlt">Skjold</option>
            <option value="FaStar">Stjerne</option>
          </select>
        </div>
        <button onClick={handleAddInspirationItem} className="add-button">
          Tilføj inspiration
        </button>
        
        {/* Vis eksisterende items */}
        <div className="items-list">
          {inspirationItems.map((item, index) => (
            <div key={index} className="list-item">
              <div className="item-content">
                <span className="item-icon">{getIcon(item.icon)}</span>
                <span className="item-title">{item.title}</span>
                <span className="item-category">({item.category})</span>
                <div className="item-description">
                  <textarea
                    value={item.description}
                    onChange={(e) => handleInspirationItemDescriptionChange(item.id, e.target.value)}
                    className="text-input textarea"
                    placeholder="Tilføj en beskrivelse..."
                    rows={3}
                  />
                </div>
              </div>
              <button
                onClick={() => handleRemoveInspirationItem(item.id)}
                className="remove-button"
              >
                Fjern
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Kontakt Sektion</h2>
        <div className="input-group">
          <label>Undertitel</label>
          <input
            type="text"
            value={contactSubtitle}
            onChange={(e) => setContactSubtitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={contactTitle}
            onChange={(e) => setContactTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={contactText}
            onChange={(e) => setContactText(e.target.value)}
            className="text-input textarea"
            rows={4}
          />
        </div>

        <div className="email-group">
          <h3>Email Information</h3>
          <div className="input-group">
            <label>Knaptekst</label>
            <input
              type="text"
              value={contactEmail.buttonText}
              onChange={(e) => setContactEmail({...contactEmail, buttonText: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Email-adresse</label>
            <input
              type="text"
              value={contactEmail.email}
              onChange={(e) => setContactEmail({...contactEmail, email: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Svartid</label>
            <input
              type="text"
              value={contactEmail.responseTime}
              onChange={(e) => setContactEmail({...contactEmail, responseTime: e.target.value})}
              className="text-input"
            />
          </div>
        </div>

        <div className="clinic-group">
          <h3>Klinik Information</h3>
          <div className="input-group">
            <label>Titel</label>
            <input
              type="text"
              value={contactClinic.title}
              onChange={(e) => setContactClinic({...contactClinic, title: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Gade</label>
            <input
              type="text"
              value={contactClinic.address.street}
              onChange={(e) => setContactClinic({
                ...contactClinic, 
                address: {...contactClinic.address, street: e.target.value}
              })}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>By</label>
            <input
              type="text"
              value={contactClinic.address.city}
              onChange={(e) => setContactClinic({
                ...contactClinic, 
                address: {...contactClinic.address, city: e.target.value}
              })}
              className="text-input"
            />
          </div>
        </div>

        <div className="hours-group">
          <h3>Åbningstider</h3>
          <div className="input-group">
            <label>Titel</label>
            <input
              type="text"
              value={contactHours.title}
              onChange={(e) => setContactHours({...contactHours, title: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Hverdage</label>
            <input
              type="text"
              value={contactHours.weekdays}
              onChange={(e) => setContactHours({...contactHours, weekdays: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Weekend</label>
            <input
              type="text"
              value={contactHours.weekend}
              onChange={(e) => setContactHours({...contactHours, weekend: e.target.value})}
              className="text-input"
            />
          </div>
        </div>

        <div className="policies-editor">
          <h3>Politikker</h3>
          {contactPolicies.map((policy, index) => (
            <div key={index} className="policy-edit">
              <div className="policy-header">
                <h4>Politik {index + 1}</h4>
              </div>
              <div className="input-group">
                <label>Titel</label>
                <input
                  type="text"
                  value={policy.title}
                  onChange={(e) => handlePolicyChange(index, 'title', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Tekst</label>
                <textarea
                  value={policy.text}
                  onChange={(e) => handlePolicyChange(index, 'text', e.target.value)}
                  className="text-input textarea"
                  rows={4}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Profil Sektion</h2>
        
        <div className="milestones-editor">
          <div className="editor-header">
            <h3>Milepæle</h3>
            <button onClick={handleAddMilestone} className="add-button">
              Tilføj milepæl
            </button>
          </div>
          {profileMilestones.map((milestone, index) => (
            <div key={index} className="milestone-item">
              <div className="milestone-inputs">
                <div className="input-group">
                  <label>År</label>
                  <input
                    type="text"
                    value={milestone.year}
                    onChange={(e) => handleMilestoneChange(index, 'year', e.target.value)}
                    className="text-input"
                  />
                </div>
                <div className="input-group">
                  <label>Beskrivelse</label>
                  <input
                    type="text"
                    value={milestone.detail}
                    onChange={(e) => handleMilestoneChange(index, 'detail', e.target.value)}
                    className="text-input"
                  />
                </div>
              </div>
              <button
                onClick={() => handleRemoveMilestone(index)}
                className="remove-button"
              >
                Fjern
              </button>
            </div>
          ))}
        </div>

        <div className="specialties-editor">
          <div className="editor-header">
            <h3>Specialeområder</h3>
            <button onClick={handleAddSpecialty} className="add-button">
              Tilføj område
            </button>
          </div>
          {profileSpecialties.areas.map((specialty, index) => (
            <div key={index} className="specialty-item">
              <div className="input-group">
                <input
                  type="text"
                  value={specialty}
                  onChange={(e) => handleSpecialtyChange(index, e.target.value)}
                  className="text-input"
                  placeholder="Skriv specialeområde..."
                />
              </div>
              <button
                onClick={() => handleRemoveSpecialty(index)}
                className="remove-button"
              >
                Fjern
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>FAQ Sektion</h2>
        <div className="input-group">
          <label>Undertitel</label>
          <input
            type="text"
            value={faqSubtitle}
            onChange={(e) => setFaqSubtitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={faqTitle}
            onChange={(e) => setFaqTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Tekst</label>
          <textarea
            value={faqText}
            onChange={(e) => setFaqText(e.target.value)}
            className="text-input textarea"
            rows={4}
          />
        </div>

        <div className="faq-editor">
          <h3>Spørgsmål og Svar</h3>
          {faqItems.map((item, index) => (
            <div key={index} className="faq-item-edit">
              <div className="faq-item-header">
                <h4>Spørgsmål {index + 1}</h4>
              </div>
              <div className="input-group">
                <label>Spørgsmål</label>
                <input
                  type="text"
                  value={item.question}
                  onChange={(e) => handleFaqItemChange(index, 'question', e.target.value)}
                  className="text-input"
                />
              </div>
              <div className="input-group">
                <label>Svar</label>
                <textarea
                  value={item.answer}
                  onChange={(e) => handleFaqItemChange(index, 'answer', e.target.value)}
                  className="text-input textarea"
                  rows={3}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminForside;
