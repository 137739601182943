import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './styles/priser.css';
import heroImage from '../images/luca-bravo-ESkw2ayO2As-unsplash.jpg';

const Priser = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState('Priser & Betingelser');
  const [pageSubtitle, setPageSubtitle] = useState('Priser og praktisk information');
  const [henvisningPriser, setHenvisningPriser] = useState({
    title: 'Med henvisning',
    firstSession: {
      label: '1. samtale',
      amount: '469,86'
    },
    followingSessions: {
      label: '2.-12. samtale',
      amount: '391,80'
    }
  });
  const [udenHenvisningPriser, setUdenHenvisningPriser] = useState({
    title: 'Uden henvisning',
    allSessions: {
      label: 'Alle samtaler',
      amount: '985'
    }
  });
  const [sessionNote, setSessionNote] = useState('Alle samtaler er i alt 45 minutter, inkl. betaling og booking af ny tid.');
  const [paymentSection, setPaymentSection] = useState({
    title: 'Betaling',
    content: ''
  });
  const [cancellationSection, setCancellationSection] = useState({
    title: 'Afbud/udeblivelse',
    content: ''
  });
  const [insuranceSection, setInsuranceSection] = useState({
    title: 'Sygesikring Danmark',
    content: ''
  });
  const [healthInsuranceSection, setHealthInsuranceSection] = useState({
    title: 'Forsikring',
    content: ''
  });
  const [ctaSection, setCtaSection] = useState({
    title: 'Har du spørgsmål eller ønsker du at booke en tid?',
    buttonText: 'Kontakt mig'
  });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'priser');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.header) {
            if (data.header.title) setPageTitle(data.header.title);
            if (data.header.subtitle) setPageSubtitle(data.header.subtitle);
          }
          if (data.henvisningPriser) setHenvisningPriser(data.henvisningPriser);
          if (data.udenHenvisningPriser) setUdenHenvisningPriser(data.udenHenvisningPriser);
          if (data.sessionNote) setSessionNote(data.sessionNote);
          if (data.paymentSection) setPaymentSection(data.paymentSection);
          if (data.cancellationSection) setCancellationSection(data.cancellationSection);
          if (data.insuranceSection) setInsuranceSection(data.insuranceSection);
          if (data.healthInsuranceSection) setHealthInsuranceSection(data.healthInsuranceSection);
          if (data.ctaSection) setCtaSection(data.ctaSection);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Fejl ved hentning af indhold:', error);
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  const scrollToContact = (e) => {
    e.preventDefault();
    
    // Hvis destinationen er en sektion på forsiden (starter med #)
    if (ctaSection.destination.startsWith('#')) {
      navigate('/');
      
      // Øg initial timeout for at sikre siden er helt loadet
      setTimeout(() => {
        let attempts = 0;
        const maxAttempts = 10;
        
        const scrollToElement = () => {
          const element = document.querySelector(ctaSection.destination);
          const headerOffset = 90;
          
          if (element) {
            // Vent et øjeblik mere før scroll for at sikre siden er stabil
            setTimeout(() => {
              const elementPosition = element.getBoundingClientRect().top;
              const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
              
              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
            }, 100);
          } else if (attempts < maxAttempts) {
            attempts++;
            setTimeout(scrollToElement, 100);
          }
        };
        
        scrollToElement();
      }, 500); // Øget fra 300ms til 500ms
    } else {
      // Hvis destinationen er en anden side
      navigate('/' + ctaSection.destination);
    }
  };

  const formatContent = (content) => {
    return content.split('\n\n').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  };

  if (isLoading) {
    return <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>;
  }

  return (
    <div className="priser-page">
      <div className="priser-hero">
        <img src={heroImage} alt="" />
        <div className="priser-hero-content">
          <span className="priser-subtitle">{pageTitle}</span>
          <h1>{pageSubtitle}</h1>
        </div>
      </div>
      
      <div className="priser-container">
        <div className="priser-content">
          <section className="priser-section">
            <div className="priser-cards">
              <div className="pris-card">
                <h3>{henvisningPriser.title}</h3>
                <div className="pris-details">
                  <div className="pris-item">
                    <span className="pris-label">{henvisningPriser.firstSession.label}</span>
                    <span className="pris-amount">{henvisningPriser.firstSession.amount} kr.</span>
                  </div>
                  <div className="pris-item">
                    <span className="pris-label">{henvisningPriser.followingSessions.label}</span>
                    <span className="pris-amount">{henvisningPriser.followingSessions.amount} kr.</span>
                  </div>
                </div>
              </div>
              <div className="pris-card">
                <h3>{udenHenvisningPriser.title}</h3>
                <div className="pris-details">
                  <div className="pris-item">
                    <span className="pris-label">{udenHenvisningPriser.allSessions.label}</span>
                    <span className="pris-amount">{udenHenvisningPriser.allSessions.amount} kr.</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="note">{sessionNote}</p>
          </section>

          <div className="info-grid">
            <section className="info-section">
              <h2>{paymentSection.title}</h2>
              {formatContent(paymentSection.content)}
            </section>

            <section className="info-section">
              <h2>{cancellationSection.title}</h2>
              {formatContent(cancellationSection.content)}
            </section>

            <section className="info-section">
              <h2>{insuranceSection.title}</h2>
              {formatContent(insuranceSection.content)}
            </section>

            <section className="info-section">
              <h2>{healthInsuranceSection.title}</h2>
              {formatContent(healthInsuranceSection.content)}
            </section>
          </div>

          <div className="priser-cta">
            <p>{ctaSection.title}</p>
            <a 
              href={ctaSection.destination} 
              onClick={scrollToContact} 
              className="cta-button"
            >
              {ctaSection.buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Priser;
