import React from 'react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './styles/dashboard.css';

function Dashboard() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/admin');
    } catch (error) {
      console.error('Fejl ved logout:', error);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="dashboard-header">
          <h1>Admin Dashboard</h1>
          <button onClick={handleLogout} className="logout-button">Log ud</button>
        </div>
        <div className="dashboard-grid">
          <div className="dashboard-item" onClick={() => navigate('/admin/forside')}>
            <h3>Forside</h3>
            <p>Administrer forsiden og dens indhold</p>
          </div>
          <div className="dashboard-item" onClick={() => navigate('/admin/behandling')}>
            <h3>Behandling</h3>
            <p>Rediger behandlingssiden og dens indhold</p>
          </div>
          <div className="dashboard-item" onClick={() => navigate('/admin/priser')}>
            <h3>Priser</h3>
            <p>Opdater priser og behandlingspakker</p>
          </div>
          <div className="dashboard-item" onClick={() => navigate('/admin/inspiration')}>
            <h3>Inspiration</h3>
            <p>Administrer inspirationsindhold og artikler</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
