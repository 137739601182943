import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import Hero from './components/hero';
import Om from './components/om';
import Behandling from './components/behandling';
import Inspiration from './components/inspiration';
import Kontakt from './components/kontakt';
import FAQ from './components/faq';
import Footer from './components/footer';
import Priser from './components/priser';
import InspirationSide from './components/inspirationSide';
import BehandlingSide from './components/behandlingSide';
import ScrollToTop from './components/ScrollToTop';
import Login from './components/login';
import Dashboard from './components/dashboard';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import AdminBehandling from './components/admin/AdminBehandling';
import AdminForside from './components/admin/AdminForside';
import AdminPris from './components/admin/AdminPris';
import AdminInspiration from './components/admin/AdminInspiration';

function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Header />
          <Routes>
            <Route path="/priser" element={<Priser />} />
            <Route path="/inspiration" element={<InspirationSide />} />
            <Route path="/behandling" element={<BehandlingSide />} />
            <Route path="/" element={
              <main>
                <Hero />
                <Om />
                <Behandling />
                <Inspiration />
                <Kontakt />
                <FAQ />
              </main>
            } />
            <Route path="/admin" element={<Login />} />
            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/behandling"
              element={
                <PrivateRoute>
                  <AdminBehandling />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/forside"
              element={
                <PrivateRoute>
                  <AdminForside />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/priser"
              element={
                <PrivateRoute>
                  <AdminPris />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/inspiration"
              element={
                <PrivateRoute>
                  <AdminInspiration />
                </PrivateRoute>
              }
            />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
