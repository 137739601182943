import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/header.css';
import MobileMenu from './mobileMenu';

const Header = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'auto';
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const scrollToProfile = (e) => {
    e.preventDefault();
    if (window.location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const scrollToElement = () => {
          const profileElement = document.querySelector('.kontakt .profile-section');
          const headerOffset = 90;
          
          if (profileElement) {
            const elementPosition = profileElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          } else {
            setTimeout(scrollToElement, 100);
          }
        };
        
        scrollToElement();
      }, 300);
    } else {
      const profileElement = document.querySelector('.kontakt .profile-section');
      const headerOffset = 90;
      
      if (profileElement) {
        const elementPosition = profileElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  const scrollToFAQ = (e) => {
    e.preventDefault();
    if (window.location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const faqElement = document.getElementById('faq');
        const headerOffset = 120;
        if (faqElement) {
          const elementPosition = faqElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }, 100);
    } else {
      const faqElement = document.getElementById('faq');
      const headerOffset = 120;
      if (faqElement) {
        const elementPosition = faqElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <header className={`header ${scrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <Link to="/" className="logo">
          <h1>Louise Munk</h1>
          <p>Psykolog</p>
        </Link>
        
        <nav className="nav-menu">
          <ul>
            <li><Link to="/" className="home-link">Forside</Link></li>
            <li><Link to="/behandling">Behandling</Link></li>
            <li><Link to="/priser">Priser</Link></li>
            <li><a href="#faq" onClick={scrollToFAQ}>Praktisk info</a></li>
            <li><a href="#profil" onClick={scrollToProfile}>Profil</a></li>
            <li><Link to="/inspiration">Inspiration</Link></li>
          </ul>
        </nav>

        <div 
          className={`mobile-menu-button ${isMenuOpen ? 'active' : ''}`} 
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <MobileMenu 
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          scrollToProfile={scrollToProfile}
          scrollToFAQ={scrollToFAQ}
        />
      </div>
    </header>
  );
};

export default Header;
