import React, { useState, useEffect } from 'react';
import './styles/behandlingSide.css';
import { FaMapMarkedAlt, FaUserFriends, FaClipboardList, FaRegComments } from 'react-icons/fa';
import heroImage from '../images/aaron-burden-dXYE1d08BiY-unsplash.jpg';
import approachImage from '../images/matthew-smith-Rfflri94rs8-unsplash.jpg';
import handlingImage from '../images/aaron-burden-Kp9z6zcUfGw-unsplash.jpg';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const BehandlingSide = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [subtitle, setSubtitle] = useState('Behandling');
  const [title, setTitle] = useState('Hvordan vælger man den "rigtige" psykolog?');
  const [introText, setIntroText] = useState('');
  const [metaphorTitle, setMetaphorTitle] = useState('At finde vej i livet');
  const [metaphorText, setMetaphorText] = useState('');
  const [explanationTitle, setExplanationTitle] = useState('');
  const [explanationText, setExplanationText] = useState('');
  const [approachTitle, setApproachTitle] = useState('');
  const [approachText, setApproachText] = useState('');
  const [expectationsTitle, setExpectationsTitle] = useState('');
  const [expectationsText, setExpectationsText] = useState('');
  const [processTitle, setProcessTitle] = useState('Behandlingsforløbet');
  const [processSteps, setProcessSteps] = useState([]);
  const [infoCards, setInfoCards] = useState([]);
  const [contactTitle, setContactTitle] = useState('');
  const [contactText, setContactText] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'behandling');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.subtitle) setSubtitle(data.subtitle);
          if (data.title) setTitle(data.title);
          if (data.introText) setIntroText(data.introText);
          if (data.metaphorTitle) setMetaphorTitle(data.metaphorTitle);
          if (data.metaphorText) setMetaphorText(data.metaphorText);
          if (data.explanationTitle) setExplanationTitle(data.explanationTitle);
          if (data.explanationText) setExplanationText(data.explanationText);
          if (data.approachTitle) setApproachTitle(data.approachTitle);
          if (data.approachText) setApproachText(data.approachText);
          if (data.expectationsTitle) setExpectationsTitle(data.expectationsTitle);
          if (data.expectationsText) setExpectationsText(data.expectationsText);
          if (data.processTitle) setProcessTitle(data.processTitle);
          if (data.processSteps) setProcessSteps(data.processSteps);
          if (data.infoCards) setInfoCards(data.infoCards);
          if (data.contactTitle) setContactTitle(data.contactTitle);
          if (data.contactText) setContactText(data.contactText);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Fejl ved hentning af indhold:', error);
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  if (isLoading) {
    return (
      <div className="behandling-side">
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
  }

  const createMarkup = (text) => {
    // Regex for at matche både email og URL
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    const urlRegex = /(https?:\/\/[^\s]+)/gi;

    // Split teksten på både emails og URLs
    const parts = text.split(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+|https?:\/\/[^\s]+)/gi);

    return parts.map((part, index) => {
      if (part.match(emailRegex)) {
        return (
          <a 
            key={index} 
            href={`mailto:${part}`}
            className="content-link"
          >
            {part}
          </a>
        );
      } else if (part.match(urlRegex)) {
        return (
          <a 
            key={index} 
            href={part} 
            target="_blank" 
            rel="noopener noreferrer"
            className="content-link"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="behandling-side">
      <div className="behandling-hero">
        <img src={heroImage} alt="" />
        <div className="behandling-hero-content">
          <span className="behandling-side__subtitle">{subtitle}</span>
          <h1 className="behandling-side__title">{title}</h1>
        </div>
      </div>
      
      <div className="behandling-side__container">
        <div className="behandling-side__content">
          <div className="behandling-side__intro-text">
            {introText.split('\n\n').map((paragraph, index) => (
              <p key={index}>{createMarkup(paragraph)}</p>
            ))}
          </div>

          <div className="behandling-side__metaphor-section">
            <div className="metaphor-box">
              <div className="metaphor-icon">
                <FaMapMarkedAlt />
              </div>
              <h2>{metaphorTitle}</h2>
              {metaphorText.split('\n\n').map((paragraph, index) => (
                <p key={index}>{createMarkup(paragraph)}</p>
              ))}
            </div>
          </div>

          <div className="behandling-side__explanation">
            <div className="explanation-box">
              <div className="content-text">
                <h3>{explanationTitle}</h3>
                {explanationText.split('\n\n').map((paragraph, index) => (
                  <p key={index}>{createMarkup(paragraph)}</p>
                ))}
              </div>
              <img src={handlingImage} alt="" className="content-image" />
            </div>
          </div>

          <div className="behandling-side__approach">
            <div className="approach-box">
              <img src={approachImage} alt="" className="content-image desktop" />
              <div className="content-text">
                <h3>{approachTitle}</h3>
                {approachText.split('\n\n').map((paragraph, index) => (
                  <p key={index}>{createMarkup(paragraph)}</p>
                ))}
              </div>
              <img src={approachImage} alt="" className="content-image mobile" />
            </div>
          </div>

          <div className="behandling-side__expectations">
            <div className="expectations-box">
              <h3>{expectationsTitle}</h3>
              {expectationsText.split('\n\n').map((paragraph, index) => (
                <p key={index}>{createMarkup(paragraph)}</p>
              ))}
            </div>
          </div>

          <div className="behandling-side__process">
            <h2>{processTitle}</h2>
            <div className="behandling-side__steps">
              {processSteps.map((step, index) => (
                <div key={index} className="behandling-side__step">
                  <div className="behandling-side__step-number">{index + 1}</div>
                  <h3>{step.title}</h3>
                  <p>{createMarkup(step.text)}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="behandling-side__info-cards">
            {infoCards.map((card, index) => (
              <div key={index} className="behandling-side__info-card">
                {card.icon === 'FaUserFriends' && <FaUserFriends className="behandling-side__info-icon" />}
                {card.icon === 'FaRegComments' && <FaRegComments className="behandling-side__info-icon" />}
                <h3>{card.title}</h3>
                <p>{createMarkup(card.text)}</p>
              </div>
            ))}
          </div>

          <div className="behandling-side__contact">
            <h2>{contactTitle}</h2>
            {contactText.split('\n\n').map((paragraph, index) => (
              <p key={index}>{createMarkup(paragraph)}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BehandlingSide;
