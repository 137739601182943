import React, { useState, useEffect } from 'react';
import './styles/faq.css';
import { FaChevronDown } from 'react-icons/fa';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqData, setFaqData] = useState({
    subtitle: 'FAQ',
    title: 'Praktisk information',
    text: 'Find svar på praktiske spørgsmål om behandling og klinikken.',
    items: []
  });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'forside');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.faq) {
            setFaqData({
              subtitle: data.faq.subtitle || faqData.subtitle,
              title: data.faq.title || faqData.title,
              text: data.faq.text || faqData.text,
              items: data.faq.items || faqData.items
            });
          }
        }
      } catch (error) {
        console.error('Fejl ved hentning af FAQ:', error);
      }
    };

    fetchContent();
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Hjælpefunktion til at konvertere tekst med links til JSX
  const createMarkup = (text) => {
    // Find URL'er i teksten
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a 
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {part}
          </a>
        );
      }
      // Bevar linjeskift i teksten
      return part.split('\n').map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i !== part.split('\n').length - 1 && <br />}
        </React.Fragment>
      ));
    });
  };

  return (
    <section className="faq" id="faq">
      <div className="faq-container">
        <div className="faq-header">
          <span className="faq-subtitle">{faqData.subtitle}</span>
          <h2>{faqData.title}</h2>
          <p>{faqData.text}</p>
        </div>

        <div className="faq-content">
          {faqData.items.map((item, index) => (
            <div 
              key={index} 
              className={`faq-item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleAccordion(index)}
            >
              <div className="faq-question">
                <h3>{item.question}</h3>
                <FaChevronDown className="faq-icon" />
              </div>
              <div className="faq-answer">
                <p>{createMarkup(item.answer)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
