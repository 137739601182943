import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './styles/login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/admin/dashboard');
    } catch (error) {
      setError('Fejl ved login. Kontroller venligst dine oplysninger.');
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Indtast venligst din email-adresse');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Email med nulstilling af kodeord er sendt. Tjek din indbakke.');
      setError('');
    } catch (error) {
      setError('Fejl ved afsendelse af nulstilling af kodeord. Kontroller email-adressen.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Admin Login</h2>
        {error && <div className="error-message">{error}</div>}
        {message && <div className="success-message">{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Log ind</button>
        </form>
        <button 
          onClick={handleForgotPassword} 
          className="forgot-password-button"
        >
          Glemt kodeord?
        </button>
      </div>
    </div>
  );
}

export default Login;
