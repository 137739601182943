import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import '../styles/AdminInspiration.css';
import { useNavigate } from 'react-router-dom';

const AdminInspiration = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Header states
  const [pageTitle, setPageTitle] = useState('Find inspiration');
  const [pageSubtitle, setPageSubtitle] = useState('Ressourcer');

  // Tilføj nye state variabler for emner og typer
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [resources, setResources] = useState([]);
  
  // State for nye emner og typer
  const [newCategory, setNewCategory] = useState('');
  const [newType, setNewType] = useState('');
  
  // State for ny ressource
  const [newResource, setNewResource] = useState({
    categories: [],
    type: '',
    titel: '',
    forfatter: '',
    vært: '',
    år: '',
    beskrivelse: '',
    platform: '',
    url: ''
  });

  // Tilføj nye state variabler for redigering
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingType, setEditingType] = useState(null);
  const [editedText, setEditedText] = useState('');

  // Tilføj ny state for redigering af ressource
  const [editingResource, setEditingResource] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'inspiration');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.header) {
            if (data.header.title) setPageTitle(data.header.title);
            if (data.header.subtitle) setPageSubtitle(data.header.subtitle);
          }
          if (data.categories) setCategories(data.categories);
          if (data.types) setTypes(data.types);
          if (data.resources) setResources(data.resources);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Fejl ved hentning af indhold:', error);
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Gem alt indhold på én gang
      const contentData = {
        header: {
          title: pageTitle,
          subtitle: pageSubtitle
        },
        categories: categories,
        types: types,
        resources: resources
      };

      await setDoc(doc(db, 'content', 'inspiration'), contentData);
      
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      console.error('Fejl ved gemning:', error);
      alert('Der skete en fejl ved gemning af ændringer');
    }
    setIsSaving(false);
  };

  const handleAddCategory = () => {
    if (newCategory.trim() && !categories.includes(newCategory.trim())) {
      const updatedCategories = [...categories, newCategory.trim()];
      setCategories(updatedCategories);
      setNewCategory('');
      
      // Gem ændringerne med det samme
      handleSaveCategories(updatedCategories);
    }
  };

  const handleAddType = () => {
    if (newType.trim() && !types.includes(newType.trim())) {
      const updatedTypes = [...types, newType.trim()];
      setTypes(updatedTypes);
      setNewType('');
      
      // Gem ændringerne med det samme
      handleSaveTypes(updatedTypes);
    }
  };

  // Nye hjælpefunktioner til at gemme enkelte dele
  const handleSaveCategories = async (updatedCategories) => {
    try {
      await setDoc(doc(db, 'content', 'inspiration'), {
        categories: updatedCategories
      }, { merge: true });
    } catch (error) {
      console.error('Fejl ved gemning af kategorier:', error);
      alert('Der skete en fejl ved gemning af kategorier');
    }
  };

  const handleSaveTypes = async (updatedTypes) => {
    try {
      await setDoc(doc(db, 'content', 'inspiration'), {
        types: updatedTypes
      }, { merge: true });
    } catch (error) {
      console.error('Fejl ved gemning af typer:', error);
      alert('Der skete en fejl ved gemning af typer');
    }
  };

  const handleRemoveCategory = async (categoryToRemove) => {
    const updatedCategories = categories.filter(category => category !== categoryToRemove);
    setCategories(updatedCategories);
    
    // Opdater ressourcer
    const updatedResources = resources
      .map(resource => ({
        ...resource,
        categories: resource.categories.filter(c => c !== categoryToRemove)
      }))
      .filter(resource => resource.categories.length > 0);
    
    setResources(updatedResources);

    // Gem både kategorier og ressourcer
    try {
      await setDoc(doc(db, 'content', 'inspiration'), {
        categories: updatedCategories,
        resources: updatedResources
      }, { merge: true });
    } catch (error) {
      console.error('Fejl ved fjernelse af kategori:', error);
      alert('Der skete en fejl ved fjernelse af kategorien');
    }
  };

  const handleRemoveType = async (typeToRemove) => {
    const updatedTypes = types.filter(type => type !== typeToRemove);
    setTypes(updatedTypes);
    
    // Opdater ressourcer
    const updatedResources = resources.filter(resource => resource.type !== typeToRemove);
    setResources(updatedResources);

    // Gem både typer og ressourcer
    try {
      await setDoc(doc(db, 'content', 'inspiration'), {
        types: updatedTypes,
        resources: updatedResources
      }, { merge: true });
    } catch (error) {
      console.error('Fejl ved fjernelse af type:', error);
      alert('Der skete en fejl ved fjernelse af typen');
    }
  };

  const handleCategoryChange = (category) => {
    setNewResource(prev => {
      const categories = prev.categories.includes(category)
        ? prev.categories.filter(c => c !== category)
        : [...prev.categories, category];
      return { ...prev, categories };
    });
  };

  const handleAddResource = async () => {
    if (newResource.titel.trim() && newResource.categories.length > 0 && newResource.type) {
      const newResourceWithId = { ...newResource, id: Date.now() };
      const updatedResources = [...resources, newResourceWithId];
      
      setResources(updatedResources);
      
      // Gem den nye ressource med det samme
      try {
        await setDoc(doc(db, 'content', 'inspiration'), {
          resources: updatedResources
        }, { merge: true });
        
        // Reset form
        setNewResource({
          categories: [],
          type: '',
          titel: '',
          forfatter: '',
          vært: '',
          år: '',
          beskrivelse: '',
          platform: '',
          url: ''
        });
      } catch (error) {
        console.error('Fejl ved gemning af ny ressource:', error);
        alert('Der skete en fejl ved gemning af ressourcen');
      }
    }
  };

  const handleRemoveResource = async (resourceId) => {
    const updatedResources = resources.filter(resource => resource.id !== resourceId);
    setResources(updatedResources);

    // Gem ændringen med det samme
    try {
      await setDoc(doc(db, 'content', 'inspiration'), {
        resources: updatedResources
      }, { merge: true });
    } catch (error) {
      console.error('Fejl ved fjernelse af ressource:', error);
      alert('Der skete en fejl ved fjernelse af ressourcen');
    }
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setEditedText(category);
  };

  const handleEditType = (type) => {
    setEditingType(type);
    setEditedText(type);
  };

  const handleSaveEdit = (type) => {
    const trimmedText = editedText.trim();
    if (!trimmedText) return;

    if (type === 'category') {
      if (editingCategory && trimmedText !== editingCategory) {
        // Opdater kategorien i listen af kategorier
        setCategories(categories.map(cat => 
          cat === editingCategory ? trimmedText : cat
        ));
        // Opdater kategorien i alle ressourcer
        setResources(resources.map(resource => ({
          ...resource,
          categories: resource.categories.map(cat =>
            cat === editingCategory ? trimmedText : cat
          )
        })));
      }
      setEditingCategory(null);
    } else if (type === 'type') {
      if (editingType && trimmedText !== editingType) {
        // Opdater typen i listen af typer
        setTypes(types.map(t => 
          t === editingType ? trimmedText : t
        ));
        // Opdater typen i alle ressourcer
        setResources(resources.map(resource => 
          resource.type === editingType 
            ? { ...resource, type: trimmedText }
            : resource
        ));
      }
      setEditingType(null);
    }
    setEditedText('');
  };

  const handleEditResource = (resource) => {
    setEditingResource({
      ...resource,
      categories: [...resource.categories] // Lav en kopi af arrays
    });
  };

  const handleUpdateResource = async () => {
    if (!editingResource) return;

    try {
      const updatedResources = resources.map(resource => 
        resource.id === editingResource.id ? editingResource : resource
      );
      
      setResources(updatedResources);
      
      await setDoc(doc(db, 'content', 'inspiration'), {
        resources: updatedResources
      }, { merge: true });

      setEditingResource(null);
    } catch (error) {
      console.error('Fejl ved opdatering af ressource:', error);
      alert('Der skete en fejl ved opdatering af ressourcen');
    }
  };

  if (isLoading) {
    return <div className="admin-loading">Indlæser...</div>;
  }

  return (
    <div className="admin-inspiration">
      <div className="admin-header">
        <div className="admin-header-left">
          <button 
            onClick={() => navigate('/admin/dashboard')} 
            className="back-button"
          >
            ← Tilbage
          </button>
          <h1>Rediger Inspiration</h1>
        </div>
        <button 
          onClick={handleSave} 
          className="save-button"
          disabled={isSaving}
        >
          {isSaving ? 'Gemmer...' : 'Gem ændringer'}
        </button>
      </div>

      {showSuccessMessage && (
        <div className="success-message">
          Ændringerne blev gemt!
        </div>
      )}

      <div className="edit-section">
        <h2>Header Sektion</h2>
        <div className="input-group">
          <label>Sidetitel</label>
          <input
            type="text"
            value={pageTitle}
            onChange={(e) => setPageTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Undertitel</label>
          <input
            type="text"
            value={pageSubtitle}
            onChange={(e) => setPageSubtitle(e.target.value)}
            className="text-input"
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Emner</h2>
        <div className="input-group">
          <label>Tilføj nyt emne</label>
          <div className="input-with-button">
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              className="text-input"
              placeholder="F.eks. Stress, Angst, Depression..."
            />
            <button onClick={handleAddCategory} className="add-button">
              Tilføj
            </button>
          </div>
        </div>
        <div className="tags-container">
          {categories.map((category, index) => (
            <div key={index} className="tag">
              {editingCategory === category ? (
                <div className="tag-edit">
                  <input
                    type="text"
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    className="text-input"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSaveEdit('category');
                      }
                    }}
                    autoFocus
                  />
                  <div className="tag-edit-buttons">
                    <button
                      onClick={() => handleSaveEdit('category')}
                      className="save-edit"
                    >
                      ✓
                    </button>
                    <button
                      onClick={() => {
                        setEditingCategory(null);
                        setEditedText('');
                      }}
                      className="cancel-edit"
                    >
                      ×
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <span onClick={() => handleEditCategory(category)}>
                    {category}
                  </span>
                  <button
                    onClick={() => handleRemoveCategory(category)}
                    className="remove-tag"
                  >
                    ×
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Typer</h2>
        <div className="input-group">
          <label>Tilføj ny type</label>
          <div className="input-with-button">
            <input
              type="text"
              value={newType}
              onChange={(e) => setNewType(e.target.value)}
              className="text-input"
              placeholder="F.eks. bog, podcast, film..."
            />
            <button onClick={handleAddType} className="add-button">
              Tilføj
            </button>
          </div>
        </div>
        <div className="tags-container">
          {types.map((type, index) => (
            <div key={index} className="tag">
              {editingType === type ? (
                <div className="tag-edit">
                  <input
                    type="text"
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    className="text-input"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSaveEdit('type');
                      }
                    }}
                    autoFocus
                  />
                  <div className="tag-edit-buttons">
                    <button
                      onClick={() => handleSaveEdit('type')}
                      className="save-edit"
                    >
                      ✓
                    </button>
                    <button
                      onClick={() => {
                        setEditingType(null);
                        setEditedText('');
                      }}
                      className="cancel-edit"
                    >
                      ×
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <span onClick={() => handleEditType(type)}>
                    {type}
                  </span>
                  <button
                    onClick={() => handleRemoveType(type)}
                    className="remove-tag"
                  >
                    ×
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="edit-section">
        <h2>Ressourcer</h2>
        <div className="resource-form">
          <div className="input-group">
            <label>Emner</label>
            <div className="categories-checkboxes">
              {categories.map((category, index) => (
                <label key={index} className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={newResource.categories.includes(category)}
                    onChange={() => handleCategoryChange(category)}
                  />
                  {category}
                </label>
              ))}
            </div>
          </div>
          <div className="input-group">
            <label>Type</label>
            <select
              value={newResource.type}
              onChange={(e) => setNewResource({...newResource, type: e.target.value})}
              className="select-input"
            >
              <option value="">Vælg type</option>
              {types.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Titel</label>
            <input
              type="text"
              value={newResource.titel}
              onChange={(e) => setNewResource({...newResource, titel: e.target.value})}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Forfatter/Vært</label>
            <input
              type="text"
              value={newResource.forfatter || newResource.vært}
              onChange={(e) => setNewResource({
                ...newResource,
                [newResource.type === 'podcast' ? 'vært' : 'forfatter']: e.target.value
              })}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Beskrivelse</label>
            <textarea
              value={newResource.beskrivelse}
              onChange={(e) => setNewResource({...newResource, beskrivelse: e.target.value})}
              className="text-input textarea"
              rows={4}
            />
          </div>
          <div className="input-group">
            <label>Link URL</label>
            <input
              type="text"
              value={newResource.url}
              onChange={(e) => setNewResource({...newResource, url: e.target.value})}
              className="text-input"
              placeholder="F.eks. https://www.example.com"
            />
          </div>
          <button onClick={handleAddResource} className="add-button full-width">
            Tilføj ressource
          </button>
        </div>

        <div className="resources-list">
          {resources.map((resource) => (
            <div key={resource.id} className="resource-item">
              {editingResource?.id === resource.id ? (
                <div className="resource-edit-form">
                  <div className="input-group">
                    <label>Emner</label>
                    <div className="categories-checkboxes">
                      {categories.map((category, index) => (
                        <label key={index} className="checkbox-label">
                          <input
                            type="checkbox"
                            checked={editingResource.categories.includes(category)}
                            onChange={() => {
                              const updatedCategories = editingResource.categories.includes(category)
                                ? editingResource.categories.filter(c => c !== category)
                                : [...editingResource.categories, category];
                              setEditingResource({
                                ...editingResource,
                                categories: updatedCategories
                              });
                            }}
                          />
                          {category}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="input-group">
                    <label>Type</label>
                    <select
                      value={editingResource.type}
                      onChange={(e) => setEditingResource({
                        ...editingResource,
                        type: e.target.value
                      })}
                      className="select-input"
                    >
                      {types.map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                  <div className="input-group">
                    <label>Titel</label>
                    <input
                      type="text"
                      value={editingResource.titel}
                      onChange={(e) => setEditingResource({
                        ...editingResource,
                        titel: e.target.value
                      })}
                      className="text-input"
                    />
                  </div>
                  <div className="input-group">
                    <label>Forfatter/Vært</label>
                    <input
                      type="text"
                      value={editingResource.forfatter || editingResource.vært || ''}
                      onChange={(e) => setEditingResource({
                        ...editingResource,
                        [editingResource.type === 'podcast' ? 'vært' : 'forfatter']: e.target.value
                      })}
                      className="text-input"
                    />
                  </div>
                  <div className="input-group">
                    <label>Beskrivelse</label>
                    <textarea
                      value={editingResource.beskrivelse}
                      onChange={(e) => setEditingResource({
                        ...editingResource,
                        beskrivelse: e.target.value
                      })}
                      className="text-input textarea"
                      rows={4}
                    />
                  </div>
                  <div className="input-group">
                    <label>Link URL</label>
                    <input
                      type="text"
                      value={editingResource.url || ''}
                      onChange={(e) => setEditingResource({
                        ...editingResource,
                        url: e.target.value
                      })}
                      className="text-input"
                      placeholder="F.eks. https://www.example.com"
                    />
                  </div>
                  <div className="edit-buttons">
                    <button onClick={handleUpdateResource} className="save-button">
                      Gem ændringer
                    </button>
                    <button 
                      onClick={() => setEditingResource(null)} 
                      className="cancel-button"
                    >
                      Annuller
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="resource-content">
                    <h3>
                      {resource.url ? (
                        <a 
                          href={resource.url} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="resource-link"
                        >
                          {resource.titel}
                          <span className="external-link-icon">↗</span>
                        </a>
                      ) : (
                        resource.titel
                      )}
                    </h3>
                    <p className="resource-meta">
                      <span className="categories-list">
                        {resource.categories.join(', ')}
                      </span>
                      {' | '}
                      <span className="type">{resource.type}</span>
                    </p>
                    <p className="resource-author">
                      {resource.forfatter ? `Forfatter: ${resource.forfatter}` : ''}
                      {resource.vært ? `Vært: ${resource.vært}` : ''}
                    </p>
                    <p className="resource-description">{resource.beskrivelse}</p>
                  </div>
                  <div className="resource-actions">
                    <button
                      onClick={() => handleEditResource(resource)}
                      className="edit-button"
                    >
                      Rediger
                    </button>
                    <button
                      onClick={() => handleRemoveResource(resource.id)}
                      className="remove-button"
                    >
                      Fjern
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminInspiration;
