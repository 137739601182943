import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import '../styles/AdminPris.css';
import { useNavigate } from 'react-router-dom';

const AdminPris = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Header states
  const [pageTitle, setPageTitle] = useState('Priser & Betingelser');
  const [pageSubtitle, setPageSubtitle] = useState('Priser og praktisk information');

  // Priser states
  const [henvisningPriser, setHenvisningPriser] = useState({
    title: 'Med henvisning',
    firstSession: {
      label: '1. samtale',
      amount: '469,86'
    },
    followingSessions: {
      label: '2.-12. samtale',
      amount: '391,80'
    }
  });

  const [udenHenvisningPriser, setUdenHenvisningPriser] = useState({
    title: 'Uden henvisning',
    allSessions: {
      label: 'Alle samtaler',
      amount: '985'
    }
  });

  // Tilføj nye state variabler
  const [sessionNote, setSessionNote] = useState('Alle samtaler er i alt 45 minutter, inkl. betaling og booking af ny tid.');
  
  const [paymentSection, setPaymentSection] = useState({
    title: 'Betaling',
    content: `Betaling foregår i starten af hver samtale.

Der kan betales med konto-overførsel. Inden første samtale skal du installere din banks mobilapp (hvis du ikke allerede har den). Jeg guider dig i første samtale ift. hvordan du sætter klinikken op som fast modtager, og derefter kan du i de efterfølgende samtaler overføre betalingen hurtigt og nemt.`
  });

  const [cancellationSection, setCancellationSection] = useState({
    title: 'Afbud/udeblivelse',
    content: `Hvis du er forhindret i at komme til den aftalte tid, skal du melde afbud pr. sms senest dagen inden, før kl. 12. Ellers betales fuld takst, uanset årsag.

Afbud pr. mail eller telefonsvarer, gælder ikke som rettidigt afbud.
Hvis du udebliver fra samtalen, betales også fuld takst.`
  });

  const [insuranceSection, setInsuranceSection] = useState({
    title: 'Sygesikring Danmark',
    content: `Hvis du er medlem af sygesikring Danmark, kan du få en del af betalingen refunderet. I første samtale i klinikken, skal du udfylde en formular med forskellige oplysninger, herunder om du er medlem af sygesikring Danmark. Når du svarer "ja" til det, indberetter jeg elektronisk til sygesikring Danmark, og du får herefter automatisk refunderet det beløb, som du er berettiget til.

Ved spørgsmål om takster eller uregelmæssigheder i udbetalingerne, skal du kontakte sygesikring Danmark.`
  });

  const [healthInsuranceSection, setHealthInsuranceSection] = useState({
    title: 'Forsikring',
    content: `Hvis du har en sundhedsforsikring, kan du ofte benytte den i min klinik, da jeg er autoriseret. Lav gerne en aftale med dit forsikringsselskab på forhånd, så kan du sende regningerne til dem lige efter sessionen og få betalingen refunderet.`
  });

  const [ctaSection, setCtaSection] = useState({
    title: 'Har du spørgsmål eller ønsker du at booke en tid?',
    buttonText: 'Kontakt mig',
    destination: '#kontakt'
  });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'priser');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.header) {
            if (data.header.title) setPageTitle(data.header.title);
            if (data.header.subtitle) setPageSubtitle(data.header.subtitle);
          }
          if (data.henvisningPriser) setHenvisningPriser(data.henvisningPriser);
          if (data.udenHenvisningPriser) setUdenHenvisningPriser(data.udenHenvisningPriser);
          if (data.sessionNote) setSessionNote(data.sessionNote);
          if (data.paymentSection) setPaymentSection(data.paymentSection);
          if (data.cancellationSection) setCancellationSection(data.cancellationSection);
          if (data.insuranceSection) setInsuranceSection(data.insuranceSection);
          if (data.healthInsuranceSection) setHealthInsuranceSection(data.healthInsuranceSection);
          if (data.ctaSection) {
            setCtaSection({
              title: data.ctaSection.title || ctaSection.title,
              buttonText: data.ctaSection.buttonText || ctaSection.buttonText,
              destination: data.ctaSection.destination || ctaSection.destination
            });
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Fejl ved hentning af indhold:', error);
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await setDoc(doc(db, 'content', 'priser'), {
        header: {
          title: pageTitle,
          subtitle: pageSubtitle
        },
        henvisningPriser,
        udenHenvisningPriser,
        sessionNote,
        paymentSection,
        cancellationSection,
        insuranceSection,
        healthInsuranceSection,
        ctaSection: {
          title: ctaSection.title,
          buttonText: ctaSection.buttonText,
          destination: ctaSection.destination
        }
      }, { merge: true });
      
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      console.error('Fejl ved gemning:', error);
      alert('Der skete en fejl ved gemning af ændringer');
    }
    setIsSaving(false);
  };

  if (isLoading) {
    return <div className="admin-loading">Indlæser...</div>;
  }

  return (
    <div className="admin-priser">
      <div className="admin-header">
        <div className="admin-header-left">
          <button 
            onClick={() => navigate('/admin/dashboard')} 
            className="back-button"
          >
            ← Tilbage
          </button>
          <h1>Rediger Priser</h1>
        </div>
        <button 
          onClick={handleSave} 
          className="save-button"
          disabled={isSaving}
        >
          {isSaving ? 'Gemmer...' : 'Gem ændringer'}
        </button>
      </div>

      {showSuccessMessage && (
        <div className="success-message">
          Ændringerne blev gemt!
        </div>
      )}

      <div className="edit-section">
        <h2>Header Sektion</h2>
        <div className="input-group">
          <label>Sidetitel</label>
          <input
            type="text"
            value={pageTitle}
            onChange={(e) => setPageTitle(e.target.value)}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Undertitel</label>
          <input
            type="text"
            value={pageSubtitle}
            onChange={(e) => setPageSubtitle(e.target.value)}
            className="text-input"
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Med Henvisning</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={henvisningPriser.title}
            onChange={(e) => setHenvisningPriser({
              ...henvisningPriser,
              title: e.target.value
            })}
            className="text-input"
          />
        </div>
        <div className="price-inputs">
          <div className="input-group">
            <label>Første samtale - Label</label>
            <input
              type="text"
              value={henvisningPriser.firstSession.label}
              onChange={(e) => setHenvisningPriser({
                ...henvisningPriser,
                firstSession: {
                  ...henvisningPriser.firstSession,
                  label: e.target.value
                }
              })}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Første samtale - Pris</label>
            <input
              type="text"
              value={henvisningPriser.firstSession.amount}
              onChange={(e) => setHenvisningPriser({
                ...henvisningPriser,
                firstSession: {
                  ...henvisningPriser.firstSession,
                  amount: e.target.value
                }
              })}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Efterfølgende samtaler - Label</label>
            <input
              type="text"
              value={henvisningPriser.followingSessions.label}
              onChange={(e) => setHenvisningPriser({
                ...henvisningPriser,
                followingSessions: {
                  ...henvisningPriser.followingSessions,
                  label: e.target.value
                }
              })}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Efterfølgende samtaler - Pris</label>
            <input
              type="text"
              value={henvisningPriser.followingSessions.amount}
              onChange={(e) => setHenvisningPriser({
                ...henvisningPriser,
                followingSessions: {
                  ...henvisningPriser.followingSessions,
                  amount: e.target.value
                }
              })}
              className="text-input"
            />
          </div>
        </div>
      </div>

      <div className="edit-section">
        <h2>Uden Henvisning</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={udenHenvisningPriser.title}
            onChange={(e) => setUdenHenvisningPriser({
              ...udenHenvisningPriser,
              title: e.target.value
            })}
            className="text-input"
          />
        </div>
        <div className="price-inputs">
          <div className="input-group">
            <label>Label</label>
            <input
              type="text"
              value={udenHenvisningPriser.allSessions.label}
              onChange={(e) => setUdenHenvisningPriser({
                ...udenHenvisningPriser,
                allSessions: {
                  ...udenHenvisningPriser.allSessions,
                  label: e.target.value
                }
              })}
              className="text-input"
            />
          </div>
          <div className="input-group">
            <label>Pris</label>
            <input
              type="text"
              value={udenHenvisningPriser.allSessions.amount}
              onChange={(e) => setUdenHenvisningPriser({
                ...udenHenvisningPriser,
                allSessions: {
                  ...udenHenvisningPriser.allSessions,
                  amount: e.target.value
                }
              })}
              className="text-input"
            />
          </div>
        </div>
      </div>

      <div className="edit-section">
        <h2>Bemærkning om samtaler</h2>
        <div className="input-group">
          <label>Note</label>
          <input
            type="text"
            value={sessionNote}
            onChange={(e) => setSessionNote(e.target.value)}
            className="text-input"
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Betalingssektion</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={paymentSection.title}
            onChange={(e) => setPaymentSection({
              ...paymentSection,
              title: e.target.value
            })}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Indhold</label>
          <textarea
            value={paymentSection.content}
            onChange={(e) => setPaymentSection({
              ...paymentSection,
              content: e.target.value
            })}
            className="text-input textarea"
            rows={6}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Afbud/udeblivelse</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={cancellationSection.title}
            onChange={(e) => setCancellationSection({
              ...cancellationSection,
              title: e.target.value
            })}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Indhold</label>
          <textarea
            value={cancellationSection.content}
            onChange={(e) => setCancellationSection({
              ...cancellationSection,
              content: e.target.value
            })}
            className="text-input textarea"
            rows={6}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Sygesikring Danmark</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={insuranceSection.title}
            onChange={(e) => setInsuranceSection({
              ...insuranceSection,
              title: e.target.value
            })}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Indhold</label>
          <textarea
            value={insuranceSection.content}
            onChange={(e) => setInsuranceSection({
              ...insuranceSection,
              content: e.target.value
            })}
            className="text-input textarea"
            rows={6}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Forsikring</h2>
        <div className="input-group">
          <label>Titel</label>
          <input
            type="text"
            value={healthInsuranceSection.title}
            onChange={(e) => setHealthInsuranceSection({
              ...healthInsuranceSection,
              title: e.target.value
            })}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Indhold</label>
          <textarea
            value={healthInsuranceSection.content}
            onChange={(e) => setHealthInsuranceSection({
              ...healthInsuranceSection,
              content: e.target.value
            })}
            className="text-input textarea"
            rows={6}
          />
        </div>
      </div>

      <div className="edit-section">
        <h2>Kontakt Sektion</h2>
        <div className="input-group">
          <label>Overskrift</label>
          <input
            type="text"
            value={ctaSection.title}
            onChange={(e) => setCtaSection({
              ...ctaSection,
              title: e.target.value
            })}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Knap tekst</label>
          <input
            type="text"
            value={ctaSection.buttonText}
            onChange={(e) => setCtaSection({
              ...ctaSection,
              buttonText: e.target.value
            })}
            className="text-input"
          />
        </div>
        <div className="input-group">
          <label>Destination</label>
          <select
            value={ctaSection.destination}
            onChange={(e) => setCtaSection({
              ...ctaSection,
              destination: e.target.value
            })}
            className="select-input"
          >
            <option value="behandling">Behandling</option>
            <option value="inspiration">Inspiration</option>
            <option value="#kontakt">Kontakt (Forside)</option>
            <option value="#om">Om (Forside)</option>
            <option value="#faq">FAQ (Forside)</option>
            <option value="#profil">Profil (Forside)</option>


          </select>

        </div>
      </div>
    </div>
  );
};

export default AdminPris;
