import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAS6Ghf9ylEaqsN9vfmVYvCznwRpgswnKI",
  authDomain: "parla-louise.firebaseapp.com",
  projectId: "parla-louise",
  storageBucket: "parla-louise.appspot.com",
  messagingSenderId: "540808491517",
  appId: "1:540808491517:web:72cac1adee3439c3042994"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;