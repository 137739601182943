import React from 'react';
import { Link } from 'react-router-dom';
import './styles/mobileMenu.css';

const MobileMenu = ({ isOpen, onClose, scrollToContact, scrollToFAQ, scrollToProfile }) => {
  const handleLinkClick = () => {
    onClose();
    document.body.style.overflow = 'auto';
  };

  return (
    <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
      <nav className="mobile-nav">
        <ul>
          <li>
            <Link to="/" onClick={handleLinkClick}>
              Forside
            </Link>
          </li>
          <li>
            <Link to="/behandling" onClick={handleLinkClick}>
              Behandling
            </Link>
          </li>
          <li>
            <Link to="/priser" onClick={handleLinkClick}>
              Priser
            </Link>
          </li>
          <li>
            <a href="#faq" onClick={(e) => {
              handleLinkClick();
              scrollToFAQ(e);
            }}>
              Praktisk info
            </a>
          </li>
          <li>
            <a href="#profil" onClick={(e) => {
              handleLinkClick();
              scrollToProfile(e);
            }}>
              Profil
            </a>
          </li>
          <li>
            <Link to="/inspiration" onClick={handleLinkClick}>
              Inspiration
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MobileMenu;
