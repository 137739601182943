import React from 'react';
import './styles/behandling.css';
import { FaRegComments, FaRegClock, FaBrain, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Behandling = () => {
  const scrollToContact = (e) => {
    e.preventDefault();
    document.getElementById('kontakt')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="behandling" id="behandling">
      <div className="behandling-container">
        <div className="behandling-header">
          <span className="behandling-subtitle">Behandling</span>
          <h2>Professionel psykologhjælp</h2>
          <p>
            Som erfaren psykolog tilbyder jeg forskellige former for terapi og behandling, 
            tilpasset dine individuelle behov og udfordringer.
          </p>
        </div>

        <div className="behandling-content">
          <div className="behandling-info">
            <div className="info-item">
              <div className="info-icon">
                <FaRegComments />
              </div>
              <h3>Individuel terapi</h3>
              <p>
                Et fortroligt rum hvor du kan udforske dine tanker og følelser, og få professionel 
                støtte til at håndtere livets udfordringer.
              </p>
            </div>

            <div className="info-item">
              <div className="info-icon">
                <FaUsers />
              </div>
              <h3>Pårørende</h3>
              <p>
                Som pårørende kan du enten få et selvstændigt forløb om dine udfordringer ved at være 
                pårørende, eller deltage i udvalgte samtaler i din næres forløb efter forudgående aftale.
                Begge muligheder er velkomne i klinikken.
              </p>
            </div>

            <div className="info-item">
              <div className="info-icon">
                <FaRegClock />
              </div>
              <h3>Forløb</h3>
              <p>
                Skræddersyede behandlingsforløb der tager udgangspunkt i dine behov, med fokus på 
                både kortsigtede og langsigtede mål.
              </p>
            </div>

            <div className="info-item">
              <div className="info-icon">
                <FaBrain />
              </div>
              <h3>Mentaliseringsbaseret terapi (MBT)</h3>
              <p>
                MBT er en veldokumenteret og nænsom metode til at udforske hvad der sker i dig, når noget bliver svært. I forløb med MBT er der desuden særligt fokus på at få dine livsvigtige relationer til andre mennesker til at fungere optimalt.
              </p>
            </div>
          </div>

          <div className="behandling-process">
            <div className="process-card">
              <h3>Sådan foregår det</h3>
              <div className="process-steps">
                <div className="step">
                  <span className="step-number">01</span>
                  <h4>Første kontakt</h4>
                  <p>Send en mail med en kort beskrivelse af din problematik samt dit telefonnummer, så ringer jeg til dig for en uforpligtende samtale om dine behov for behandling.</p>
                </div>
                <div className="step">
                  <span className="step-number">02</span>
                  <h4>Indledende samtale</h4>
                  <p>Vi mødes og taler om din situation og hvilken type behandling der passer bedst.</p>
                </div>
                <div className="step">
                  <span className="step-number">03</span>
                  <h4>Behandlingsplan</h4>
                  <p>Sammen lægger vi en plan for dit forløb med konkrete mål og tidsperspektiv.</p>
                </div>
                <div className="step">
                  <span className="step-number">04</span>
                  <h4>Løbende evaluering</h4>
                  <p>Vi følger op på din udvikling og tilpasser behandlingen efter behov.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="behandling-cta">
          <a href="#kontakt" onClick={scrollToContact} className="cta-button primary">
            Kontakt mig
          </a>
          <Link to="/behandling" className="cta-button secondary">
            Læs mere
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Behandling;
