import React, { useState, useEffect } from 'react';
import './styles/inspiration.css';
import { FaHeartbeat, FaBrain, FaCloud, FaHeart, FaShieldAlt, FaStar } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Inspiration = () => {
  const navigate = useNavigate();
  const [inspirationItems, setInspirationItems] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'forside');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.inspiration) {
            if (data.inspiration.items) {
              setInspirationItems(data.inspiration.items);
            }
          }
        }
      } catch (error) {
        console.error('Fejl ved hentning af inspiration items:', error);
      }
    };

    fetchContent();
  }, []);

  const getIcon = (iconName) => {
    switch(iconName) {
      case 'FaHeartbeat':
        return <FaHeartbeat />;
      case 'FaBrain':
        return <FaBrain />;
      case 'FaCloud':
        return <FaCloud />;
      case 'FaHeart':
        return <FaHeart />;
      case 'FaShieldAlt':
        return <FaShieldAlt />;
      case 'FaStar':
        return <FaStar />;
      default:
        return null;
    }
  };

  const handleItemClick = (category) => {
    navigate(`/inspiration?emne=${encodeURIComponent(category)}`);
  };

  return (
    <section className="inspiration" id="inspiration">
      <div className="inspiration-container">
        <div className="inspiration-header">
          <span className="inspiration-subtitle">Inspiration</span>
          <h2>Ressourcer til din udvikling</h2>
          <p>
            Her finder du inspiration og viden om forskellige psykologiske emner. 
            Udforsk ressourcerne og find hjælp til netop dine udfordringer.
          </p>
        </div>

        <div className="inspiration-grid">
          {inspirationItems.map((item) => (
            <div 
              key={item.id} 
              className="inspiration-item"
              onClick={() => handleItemClick(item.category)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleItemClick(item.category);
                }
              }}
            >
              <div className="inspiration-icon">
                {getIcon(item.icon)}
              </div>
              <div className="inspiration-content">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <div className="inspiration-link">
                  Udforsk ressourcer
                  <span className="arrow">→</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Inspiration;
