import React from 'react';
import './styles/om.css';
import profileImage from '../images/Louise Munk-61.jpg';
import { FaHeart, FaBrain, FaUsers, FaStar } from 'react-icons/fa';

const Om = () => {
  const scrollToContact = (e) => {
    e.preventDefault();
    document.getElementById('kontakt').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="om" id="om">
      <div className="om-container">
        <div className="profile-section">
          <div className="profile-container">
            <img 
              src={profileImage} 
              alt="Louise Munk" 
              className="profile-image"
            />
          </div>
          <div className="profile-content">
            <h2>Psykolog Louise Munk</h2>
            <div className="contact-info">
              <p className="address">
                Dytmærsken 8, 1.<br />
                8900 Randers C
              </p>
              <p className="contact">
                Tlf.: <a href="tel:30481303">30 48 13 03</a><br />
                Mail: <a href="mailto:louisemunk@protonmail.com">louisemunk@protonmail.com</a>
              </p>
              <p className="business-info">
                CVR.NR.: 40220747<br />
                Ydernummer: 771449
              </p>
            </div>
          </div>
        </div>

        <div className="om-content">
          <div className="om-header">
            <span className="om-subtitle">Om mig</span>
            <h2>Erfaren psykolog med fokus på dig</h2>
            <p>
              Med over 10 års erfaring som psykolog har jeg specialiseret mig i at hjælpe mennesker 
              med at finde deres indre styrke og skabe positive forandringer i deres liv.
            </p>
          </div>
          <div className="om-features">
            <div className="feature-item">
              <div className="feature-icon">
                <FaHeart />
              </div>
              <div className="feature-text">
                <h3>Empatisk tilgang</h3>
                <p>Skaber et trygt og forstående rum, hvor du kan være dig selv og udforske dine tanker og følelser i dit eget tempo.</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon">
                <FaBrain />
              </div>
              <div className="feature-text">
                <h3>Evidensbaseret</h3>
                <p>Arbejder med dokumenterede metoder og teknikker, der er udviklet på baggrund af den nyeste forskning inden for psykologi.</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon">
                <FaUsers />
              </div>
              <div className="feature-text">
                <h3>Individuel tilpasning</h3>
                <p>Tilrettelægger forløbet efter dine specifikke behov og mål, så du får den bedst mulige støtte og udvikling.</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon">
                <FaStar />
              </div>
              <div className="feature-text">
                <h3>Bred erfaring</h3>
                <p>Har gennem årene hjulpet mennesker med forskellige udfordringer og livssituationer til at opnå positive forandringer.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Om;
