import React from 'react';
import './styles/footer.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-info">
            <div className="footer-brand">
              <h3>Louise Munk</h3>
              <p>Psykolog</p>
            </div>
            <div className="footer-contact">
              <div className="contact-item">
                <FaPhone />
                <span>30 48 13 03</span>
              </div>
              <div className="contact-item">
                <FaEnvelope />
                <span>louisemunk@protonmail.com</span>
              </div>
              <div className="contact-item">
                <FaMapMarkerAlt />
                <span>Dytmærsken 8, 1., 8900 Randers C</span>
              </div>
            </div>
          </div>

          <div className="footer-links">
            <div className="links-column">
              <h4>Navigation</h4>
              <ul>
                <li><a href="/">Forside</a></li>
                <li><a href="/behandling">Behandling</a></li>
                <li><a href="/priser">Priser</a></li>
                <li><a href="/inspiration">Inspiration</a></li>
              </ul>
            </div>

            <div className="links-column">
              <h4>Information</h4>
              <ul>
                <li><a href="#faq">Praktisk info</a></li>
                <li><a href="#kontakt">Kontakt</a></li>
                <li>
                  <a 
                    href="/documents/Privatlivspolitik i klinikken.pdf" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Privatlivspolitik
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="copyright">
            <p>
              &copy; {new Date().getFullYear()} Psykolog Louise Munk. 
              Alle rettigheder forbeholdes.<br />
              Skabt af <a href="https://parlabranding.dk" target="_blank" rel="noopener noreferrer">Parla Branding</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
