import React, { useState, useEffect } from 'react';
import './styles/inspirationSide.css';
import { FaBook, FaHeadphones, FaFilm, FaTv, FaSearch } from 'react-icons/fa';
import heroImage from '../images/adrien-tutin-x8xJpClTvR0-unsplash.jpg';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const InspirationSide = () => {
  const [activeCategory, setActiveCategory] = useState('alle');
  const [activeTypes, setActiveTypes] = useState(['alle']);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState('Find inspiration');
  const [pageSubtitle, setPageSubtitle] = useState('Ressourcer');
  
  // Nye state variabler for data fra Firestore
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'inspiration');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.header) {
            if (data.header.title) setPageTitle(data.header.title);
            if (data.header.subtitle) setPageSubtitle(data.header.subtitle);
          }
          if (data.categories) setCategories(data.categories);
          if (data.types) setTypes(data.types);
          if (data.resources) setResources(data.resources);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Fejl ved hentning af indhold:', error);
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emne = params.get('emne');
    if (emne) {
      setActiveCategory(emne);
    }
  }, []);

  const getFilteredContent = () => {
    if (!resources) return [];

    return resources.filter(resource => {
      // Filtrer efter kategori
      const categoryMatch = activeCategory === 'alle' || 
        (resource.categories && resource.categories.includes(activeCategory));

      // Filtrer efter type
      const typeMatch = activeTypes.includes('alle') || 
        (resource.type && activeTypes.includes(resource.type));

      // Filtrer efter søgning
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        return (
          categoryMatch && 
          typeMatch && 
          (
            resource.titel.toLowerCase().includes(searchLower) ||
            resource.beskrivelse.toLowerCase().includes(searchLower) ||
            (resource.forfatter && resource.forfatter.toLowerCase().includes(searchLower)) ||
            (resource.vært && resource.vært.toLowerCase().includes(searchLower)) ||
            (resource.categories && resource.categories.some(cat => 
              cat.toLowerCase().includes(searchLower)
            ))
          )
        );
      }

      return categoryMatch && typeMatch;
    });
  };

  const getIcon = (type) => {
    switch(type) {
      case 'bog':
        return <FaBook />;
      case 'podcast':
        return <FaHeadphones />;
      case 'film':
        return <FaFilm />;
      case 'serie':
        return <FaTv />;
      default:
        return <FaBook />;
    }
  };

  if (isLoading) {
    return <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>;
  }

  return (
    <div className="inspiration-page">
      <div className="inspiration-hero">
        <img src={heroImage} alt="" />
        <div className="inspiration-hero-content">
          <span className="inspiration-subtitle">{pageSubtitle}</span>
          <h1>{pageTitle}</h1>
        </div>
      </div>

      <div className="inspiration-container">
        <div className="search-and-filter">
          <div className="search-section">
            <div className="search-container">
              <FaSearch className="search-icon" />
              <input
                type="text"
                placeholder="Søg efter titel, forfatter eller beskrivelse..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
          </div>

          <div className="filter-section">
            <div className="filter-group">
              <div className="filter-header">
                <span className="filter-title">Filtrer efter emne</span>
                <span className="filter-count">{categories.length}</span>
              </div>
              <div className="category-filters">
                <button 
                  className={`category-button ${activeCategory === 'alle' ? 'active' : ''}`}
                  onClick={() => setActiveCategory('alle')}
                >
                  Alle emner
                </button>
                {categories.map((category) => (
                  <button
                    key={category}
                    className={`category-button ${activeCategory === category ? 'active' : ''}`}
                    onClick={() => setActiveCategory(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>

            <div className="filter-group">
              <div className="filter-header">
                <span className="filter-title">Filtrer efter type</span>
                <span className="filter-count" data-selected={activeTypes.includes('alle') ? '0' : activeTypes.length}>
                  {types.length}
                </span>
              </div>
              <div className="type-filters">
                <button
                  className={`type-button ${activeTypes.includes('alle') ? 'active' : ''}`}
                  onClick={() => setActiveTypes(['alle'])}
                >
                  Alle typer
                </button>
                {types.map((type) => (
                  <button
                    key={type}
                    className={`type-button ${activeTypes.includes(type) ? 'active' : ''}`}
                    onClick={() => {
                      if (type === 'alle') {
                        setActiveTypes(['alle']);
                      } else {
                        const newTypes = activeTypes.includes('alle') 
                          ? [type]
                          : activeTypes.includes(type)
                            ? activeTypes.filter(t => t !== type)
                            : [...activeTypes, type];
                        setActiveTypes(newTypes.length === 0 ? ['alle'] : newTypes);
                      }
                    }}
                  >
                    {getIcon(type)}
                    <span>{type}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="inspiration-grid">
          {getFilteredContent().map((resource) => (
            <div key={resource.id} className="inspiration-card">
              <div className="card-icon">
                {getIcon(resource.type)}
              </div>
              <div className="card-content">
                <span className="content-type">{resource.type}</span>
                <h3>
                  {resource.url ? (
                    <a 
                      href={resource.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="resource-link"
                    >
                      {resource.titel}
                      <span className="external-link-icon">↗</span>
                    </a>
                  ) : (
                    resource.titel
                  )}
                </h3>
                {resource.forfatter && <p className="author">Af: {resource.forfatter}</p>}
                {resource.vært && <p className="author">Vært: {resource.vært}</p>}
                <p className="description">{resource.beskrivelse}</p>
                <div className="category-tags">
                  {resource.categories.map((category, index) => (
                    <span key={index} className="category-tag">{category}</span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InspirationSide;
