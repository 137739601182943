import React, { useState, useEffect } from 'react';
import './styles/hero.css';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Hero = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [heroSubtitle, setHeroSubtitle] = useState('Psykolog i Randers');
  const [heroTitle, setHeroTitle] = useState('Louise Munk');
  const [heroText, setHeroText] = useState('Specialiseret i at hjælpe mennesker med at finde deres indre styrke og skabe positive forandringer i deres liv.');
  const [heroButtons, setHeroButtons] = useState([
    {
      text: 'Læs mere',
      link: 'behandling',
      type: 'primary'
    },
    {
      text: 'Kontakt',
      link: '#kontakt',
      type: 'secondary'
    }
  ]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'content', 'forside');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.hero) {
            if (data.hero.subtitle) setHeroSubtitle(data.hero.subtitle);
            if (data.hero.title) setHeroTitle(data.hero.title);
            if (data.hero.text) setHeroText(data.hero.text);
            if (data.hero.buttons) setHeroButtons(data.hero.buttons);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Fejl ved hentning af indhold:', error);
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  if (isLoading) {
    return (
      <section className="hero" id="hero">
        <div className="hero-container">
          <div className="hero-content loading">
            {/* Viser loading skeleton eller spinner her hvis ønsket */}
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="hero" id="hero">
      <div className="hero-container">
        <div className="hero-content">
          <span className="hero-subtitle">{heroSubtitle}</span>
          <h1>{heroTitle}</h1>
          <p>{heroText}</p>
          <div className="hero-buttons">
            {heroButtons.map((button, index) => (
              <a 
                key={index}
                href={button.link} 
                className={`cta-button ${button.type}`}
              >
                {button.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
